import "./BottomNavBar.css"
import React from 'react'

import instagram from "../instagram.svg"
import bottomimg from "../bottomimg.png"
import { Link } from 'react-router-dom';
export default function BottomNavBar() {
    return (
        <div className="bottom-bar">

            <div className="bottom-bar-div">

                <div className='bottom-image-div2'>

                    <img className="bottom-image" src={bottomimg} alt='house' height={'140px'} width={'175px'} />


                </div>


                <div>
                    <Link to="/about-us" style={{ fontWeight: 'bolder', color: 'white', textDecoration: 'none' }}>
                        <b className="bottom-text">About Us</b>
                    </Link>
                    <Link to="/privacy-policy" style={{ fontWeight: 'bolder', color: 'white', textDecoration: 'none' }}>
                        <b className="bottom-text">Privacy Policy</b>
                    </Link>
                    <Link to="/terms-of-use" style={{ fontWeight: 'bolder', color: 'white', textDecoration: 'none' }}>
                        <b className="bottom-text">Terms of use</b>
                    </Link>

                </div>


            </div>


            <a href="https://instagram.com/gearupfrontier" target="_blank" rel="noopener noreferrer" className="bottom-bar-text">
                <div className="socials">
                    <img src={instagram} alt='house' height={'48px'} width={'48px'} />
                    <b className="bottom-bar-text">instagram.com/gearupfrontier</b>
                </div>

            </a>






            <div className="bottom-text-column">



                <p className="bottom-bar-text">
                    *By promoting our products, we may earn a commission through our affiliate programs for each sale.

                </p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    <p className="bottom-bar-text">
                        Contact Us : contact@gearupfrontier.com
                    </p>
                </div>
                <p className="bottom-bar-text">
                    GearUpFrontier © 2024 All Rights Reserved.
                </p>



            </div>





        </div>


    )
}