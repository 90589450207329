import React from 'react'
import Navbar from '../../components/Navbar';
import eight from './8.jpg'
import seven from './7.jpg'
import six from './6.jpg'
import five from './5.jpg'
import four from './4.jpg'
import three from './3.jpg'
import two from './2.jpg'
import one from './1.jpg'
import '../Blogs-Template.css'
import BottomNavBar from '../../components/BottomNavBar';
import Background from './back.jpg'
import { Helmet } from "react-helmet";
import axe from "../best-axes-for-camping/axe.jpg"

import jacket from "../top-5-suggestions-for-winter-jackets/winterjacket.jpg"
import backpack from "../best-backpacks-for-camping/back.jpg"
import shoes from "../best-shoes-for-hikers/back.jpg"
import { Link } from "react-router-dom";

const below100 = ({ title, description, canonicalUrl }) => {
    return (
        <div className='template-overflow'>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div id='pop-bar'>
                <Navbar />
            </div>
            <div className='blog-main-div'>

                <div className='blog-top-div'>
                    <div className='blog-left-div'>

                        <div className='blog-column-div1'>

                            <div className='zero-padding' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {/* BLOG BAŞLIĞI */}
                                <h2 className='template-header'>
                                    Best Camping Tents Below $100
                                </h2>
                                {/* BLOG TARIHI */}
                                <p className='template-p-times' style={{ fontSize: '22px', fontWeight: '600' }}>
                                    April 21st 2024
                                </p>
                                <img alt='images' style={{ objectFit: 'contain' }} src={Background} width={'100%'} height={'100%'} ></img>
                                <p className='template-p-times'>
                                    By promoting our products, we may earn a commission through our affiliate programs for each sale.
                                </p>
                                {/* BLOG GİRİŞ PARAGRAFI */}
                                <p className='paragraphs'>
                                    Want to experience the natural world without going over budget? Those who enjoy camping know that selecting the ideal tent can make or break a trip. Thankfully, high quality doesn't necessarily have to cost a lot. We'll look at the top camping tents under $100 in this list, demonstrating that price doesn't have to mean sacrificing comfort or toughness. These reasonably priced solutions will have you setting up your tent and enjoying the great outdoors in no time, regardless of your experience level.
                                </p>

                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        8) Coleman Skydome Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={eight} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    24.7"L x 5.42"W x 9.38"H
                                                </li>
                                                <li className='spec-text'>
                                                    2/4/6 Person Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Blackberry and Blue color
                                                </li>
                                                <li className='spec-text'>
                                                    Material Nylon
                                                </li>

                                                <li className='spec-text'>
                                                    Extra Storage Included
                                                </li>
                                                <li className='spec-text' >
                                                    5 Minute Setup
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof(1000MM)
                                                </li>
                                                <li className='spec-text'>
                                                    Withstand 35MPH Winds
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Waterproof(1000MM)
                                            </li>
                                            <li className='list-padding'>
                                                Sets up in under 5 minutes
                                            </li>
                                            <li className='list-padding'>
                                                20% more headroom
                                            </li>
                                            <li className='list-padding'>
                                                Great for car camping or backpacking
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Poles are connected to the tent
                                            </li>
                                            <li className='list-padding'>
                                                The carry bag is miniscule
                                            </li>
                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/44sH0jv'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $99.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Convenience and comfort are essential elements while camping, and the Coleman Skydome Camping Tent shines in both areas. This family dome tent, which comes in sizes to fit 2, 4, or 6 people, has an incredibly short setup time of just 5 minutes because of its unique design. Whether you're an experienced camper or a novice, less time spent fumbling with tent poles translates into more time spent taking advantage of nature. The Coleman Skydome's strong frame, which can resist winds of up to 35 mph, is one of its best qualities. Because of its durable construction, you can feel confident and at ease even in inclement weather. You don't need to worry about unexpected rain showers or strong winds since the Skydome has you protected.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Very easy to assemble as the rods come attached to the tent on the corners, and each segment is connected by springy cord which makes the final poles easy to assemble."
                                    </p>


                                </div>

                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        7) CAMEL CROWN Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={seven} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    15.74"L x 7.87"W x 7.87"H
                                                </li>
                                                <li className='spec-text'>
                                                    Up to 4 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Blue, Green and Red Color
                                                </li>
                                                <li className='spec-text'>
                                                    Material Polyester, Taffeta, Fiberglass, Fabric
                                                </li>

                                                <li className='spec-text'>
                                                    Fabric 2 doors and 4 Mesh Windows
                                                </li>


                                                <li className='spec-text'>
                                                    Waterproof(2000MM)
                                                </li>
                                                <li className='spec-text'>
                                                    Windproof
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Waterproof 4 Season 4 Person Tent
                                            </li>
                                            <li className='list-padding'>
                                                Durable Material With Polyester and Taffeta
                                            </li>
                                            <li className='list-padding'>
                                                Easy to Carry
                                            </li>
                                            <li className='list-padding'>
                                                Mesh Screen
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Zipper Problems
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/44qMMCg'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $74.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        With the CAMEL CROWN tents, preparing for camp shouldn't be difficult at all. You can spend more time enjoying the great outdoors and less time struggling with poles because of how quickly and easily they put up. You can set up your tent quickly thanks to its folding design, which frees up more time for you to explore your surroundings. The waterproof design of the CAMEL CROWN tents is one of its best qualities. These tents, which are made of strong fabrics and have waterproof coatings, offer dependable protection from rain and moisture, ensuring that you will always be dry and comfortable. You can put an end to wet clothes and soggy sleeping bags with these tents.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "This tent is prefect. Took about 20 minutes to put up. Wasn't very difficult. I had a cot in it with plenty of extra room. Im 5'5 and was able to stand in the middle of the tent. there is plenty of room for one or two ppl. Maybe more but it was just me with a cot. That took half the tent. Im sure two cots could fit with no problem."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        6) Slumberjack Aspen Grove
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={six} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    192"L x 108"W x 72"H
                                                </li>
                                                <li className='spec-text'>
                                                    8 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Green and White Color
                                                </li>
                                                <li className='spec-text'>
                                                    Material Polyethylene, Taffeta and Fiberglass
                                                </li>

                                                <li className='spec-text'>
                                                    26.6 Pounds
                                                </li>
                                                <li className='spec-text' >
                                                    3 Seasons
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof (600 Inch)
                                                </li>

                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Waterproof 3 Season 8 Person Tent
                                            </li>
                                            <li className='list-padding'>
                                                Extra Ventilation
                                            </li>
                                            <li className='list-padding'>
                                                Room Divider
                                            </li>
                                            <li className='list-padding'>
                                                Carry Duffle Included
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Quality Lacking
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3wmRQLl'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $75.00 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Having a durable and roomy tent is vital for family camping trips, and the Slumberjack Aspen Grove 8-Person Hybrid Dome Tent meets all of these requirements. For outdoor enthusiasts looking for comfort and convenience under the stars, this tent is revolutionary because of its creative design, sturdy construction, and generous area. The roomy interior of the Slumberjack Aspen Grove, which can easily fit up to eight people, is one of its most striking characteristics. There's enough space to spread out, unwind, and take in the beautiful outdoors whether you're camping with loved ones or close friends. Furthermore, this tent's hybrid dome form provides the appropriate amount of headroom and floor area, making it suitable for both sleeping and socializing.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Liked this tent so much, when we found out they were being discontinued, we bought an extra. Large, easy to setup with some practice, zippers seem solid, and love the view at night when the rain fly isn't needed. The other reason we liked it is the rain fly covers all the areas rain can get it. Instead of a tiny fly over the top, this fly covers everything. Get yours before they are all gone."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        5) Coleman Dome Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={five} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    10"L x 10"W x 5.98"H
                                                </li>
                                                <li className='spec-text'>
                                                    6 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Palm Green Color
                                                </li>
                                                <li className='spec-text'>
                                                    Material Fiberglass, Polyester
                                                </li>

                                                <li className='spec-text'>
                                                    Wind and Rain Tested
                                                </li>
                                                <li className='spec-text' >
                                                    3 Seasons
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof(3000MM)
                                                </li>
                                                <li className='spec-text'>
                                                    Windproof (35+ MPH)
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Waterproof 4 Season 6 Person Tent
                                            </li>
                                            <li className='list-padding'>
                                                WeatherTec System
                                            </li>
                                            <li className='list-padding'>
                                                Windproof (35+ MPH)
                                            </li>
                                            <li className='list-padding'>
                                                Good Ventilation
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Zipper Problems
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3Qu7Odt'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $85.00 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        The Coleman Sundome Camping Tent stands up to the brand's longstanding reputation as a high-quality outdoor product. This tent's user-friendly features, robust construction, and dependable performance are made to enrich any outdoor adventure, regardless of experience level. The Coleman Sundome fulfills the promise of an easy and stress-free camp setup with its straightforward setup. With its quick-to-assemble Insta-Clip pole attachments and snag-free continuous pole sleeves, this tent lets you spend more time enjoying the great outdoors and less time fumbling with poles. Plus, even inexperienced tent pitchers can easily set up this tent because to its dome construction and color-coded poles.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "It's an inexpensive tent. If you want a bulletproof tent then go to REI and spend 400 bucks. We have had this Coleman tent for 18 months now. It works great as long as there is not heavy windy rain. The elastic in the poles does break but this does not effect the functionality of the pikes — they still work just as intended. The zippers are fine and have not broken. The stakes were weak so I bought some upgraded stakes on Amazon for less than $10. The tent is big inside and easy to set up and easy to take down and fold. For the price this Coleman tent is a good buy and I would purchase again. Recommended."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        4) MOON LENCE Pop Up Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={four} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    94.5"L x 82.6"W x 49.2"H
                                                </li>
                                                <li className='spec-text'>
                                                    4 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Green, Blue, Orange Color
                                                </li>
                                                <li className='spec-text'>
                                                    Material Polyester
                                                </li>

                                                <li className='spec-text'>
                                                    UV Protection
                                                </li>
                                                <li className='spec-text' >
                                                    4 Seasons
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof(2000MM)
                                                </li>
                                                <li className='spec-text'>
                                                    Windproof
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Easy Setup and Portability
                                            </li>
                                            <li className='list-padding'>
                                                Spacious and Versatile
                                            </li>
                                            <li className='list-padding'>
                                                Smart Interior Design
                                            </li>
                                            <li className='list-padding'>
                                                All-Season Performance
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Pole Problems
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3UsBCZj'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $99.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        A game-changer for outdoor enthusiasts seeking comfort and convenience without compromising quality is the MOON LENCE Pop Up Tent. This foldable quick tent is the ideal option for hiking, camping, and mountaineering excursions because it is durable and can fit up to four people. The MOON LENCE Pop Up Tent makes it easy to set up camp, as it should be. With its unique automatic setup technology, this tent requires no complicated pole assembly and can be pitched quickly. To enjoy more time in the great outdoors and less time erecting the tent, just unstrap it and watch it pop up into shape. But the MOON LENCE Pop Up Tent isn't just about ease of use; it's weatherproof as well. With its sturdy construction, waterproof and windproof design, and sturdy construction, this tent offers dependable defense against wind, rain, and other inclement weather.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Ordered this tent for a four-day camping trip in Hawaii. The campground was windy and rain came and went throughout the four-day. This baby held up really well against Hawaiian wind and had so many “windows” to let air circulate especially the two large entrance and back windows which allowed cross-breezes to flow through."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        3) VEVOR Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={three} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    20"L x 12"W x 10"H
                                                </li>
                                                <li className='spec-text'>
                                                    6 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Green Color
                                                </li>
                                                <li className='spec-text'>
                                                    Material Fiberglass
                                                </li>

                                                <li className='spec-text'>
                                                    UV Protection
                                                </li>
                                                <li className='spec-text' >
                                                    4 Seasons
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof(2000MM-3000MM)
                                                </li>
                                                <li className='spec-text'>
                                                    Windproof
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Waterproof 4 Season 6 Person Tent
                                            </li>
                                            <li className='list-padding'>
                                                High Density Mesh Windows
                                            </li>
                                            <li className='list-padding'>
                                                High Quality 3F Zipper
                                            </li>

                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Manual Assembly
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3UMiLtC'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $99.89 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Setting up camp has never been easier thanks to the VEVOR tent's simple and intuitive setup process. With just a few quick steps, I had the tent pitched and ready to go, allowing me to spend more time enjoying nature and less time wrestling with poles and stakes. Plus, the lightweight design makes it easy to transport, so I can take it with me wherever my adventures lead. When it comes to outdoor gear, durability is important, and the VEVOR Camping Tent delivers. Being made of premium materials and having a waterproof design, this tent offers dependable weather protection, keeping me dry and cozy even in the most erratic of weather situations.

                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "The tent itself is very nice. It's very simple to assemble, and I love the green color. However the manufacturer says that it is a 4-6 person tent. If children are just using it then I can see that, but for adults I was only able to get 4 of us in there lying side by side like sardines. So if you are looking for a tent that fits 4-6 adults comfortably then you need a bigger one. However other than that this makes a great 2-3 person tent."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        2) Loyeahcamp 4 Person Blackout Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={two} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    100"L x 84.6"W x 61"H
                                                </li>
                                                <li className='spec-text'>
                                                    4 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Blue, Green Color
                                                </li>
                                                <li className='spec-text'>
                                                    Material Tafetta Fabric and Polyester/Blockout
                                                </li>

                                                <li className='spec-text'>
                                                    Blackout Tent
                                                </li>
                                                <li className='spec-text' >
                                                    3 Seasons
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof(3000MM)
                                                </li>
                                                <li className='spec-text'>
                                                    Thicker Stakes
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Large Space
                                            </li>
                                            <li className='list-padding'>
                                                3000MM Waterproof
                                            </li>
                                            <li className='list-padding'>
                                                Quick & Easy Setup/Portable
                                            </li>
                                            <li className='list-padding'>
                                                Chimney Effect
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Intuitive Setup
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3UoGbUm'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $69.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        The spacious inside of the Loyeahcamp tent gives you plenty of space to sleep and store your belongings, making it the perfect choice for adventures with friends or family on camping trips. Its waterproof rainfly also meant that I never had to worry about getting wet during sudden rainfall, guaranteeing a dry and comfortable camping trip from beginning to end. It turned out that the Loyeahcamp tent setup was surprisingly simple. I was able to quickly set up the tent because to its easy-to-use design, which gave me more free time to spend in nature. Using a double-layered construction strengthens protection and increases durability, giving users more confidence to face a variety of environmental situations. The tent's blackout feature is one of its standout features; it's designed to reduce sunlight penetration and create a more comfortable sleeping environment. This feature, which allowed me to sleep until the early hours of the morning, proved to be really helpful to me when I went camping. All things considered, the Loyeahcamp 4 Person Blackout Camping Tent really impressed me. The blackout design, large interior, and ease of setup make it an ideal option for outdoor enthusiasts looking for convenience and comfort on their camping trips.




                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Great summer tent. Has a lot of room inside. It is taller inside than some 4 person tents I've used in the past. The only issue is half of the top of this is mesh. It easily gets cold especially without the rain and wind top cover. I was easily able to put a folding bed inside and had more than enough room to put 1 or 2 more. I will say for longevity you should get a tarp underneath as it doesn't look like a strong bottom but it did last in rocky terrain."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        1) Night Cat Pop-up Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={one} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    114.17"L x 78.74"W x 45.28"H
                                                </li>
                                                <li className='spec-text'>
                                                    2 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Green Color
                                                </li>
                                                <li className='spec-text'>
                                                    Material Polyester Tafetta
                                                </li>

                                                <li className='spec-text'>
                                                    Cool Ventilation with Porch
                                                </li>
                                                <li className='spec-text' >
                                                    3 Seasons
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof(2000MM)
                                                </li>
                                                <li className='spec-text'>
                                                    30 Sec Folding Back
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Package 40% Smaller
                                            </li>
                                            <li className='list-padding'>
                                                2 Big Doors and 2 Wide Windows
                                            </li>
                                            <li className='list-padding'>
                                                Instant Setup & Portable
                                            </li>
                                            <li className='list-padding'>
                                                Dual-Side Zipper
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Waterproof?
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3UseKZG'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $85.59 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        The Night Cat Pop-up Camping Tent is a compelling choice for outdoor enthusiasts looking for portability and simplicity. Its most notable feature is its pop-up design, which offers a hassle-free setup in seconds. It is an appealing option for individuals who value efficiency and simplicity in their camping equipment because of its immediate pitching feature, which does away with the need for complex pole construction. The Night Cat tent has surprisingly large inside area, comfortably seating up to two people, despite its small folded size. This makes it the perfect choice for travelers traveling alone or for couples looking for a comfortable hideaway in the woods. Furthermore, the mesh windows and door of the tent offer superior ventilation, encouraging air movement and reducing the accumulation of humidity in warmer climates. All things considered, the Night Cat Pop-up Camping Tent provides an alluring combination of portability, durability, and simplicity. Its simple design, dependable construction, and quick setup make it a sensible option for single individuals or couples looking for a hassle-free camping experience. This tent is an incredibly adaptable and sturdy outdoor shelter, perfect for trips into the backcountry or to music festivals.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "I am a US Army infantry soldier and have been in going on 2 decades now. I wish I had this my whole career. The way the video shows them popping it up and folding it down is exactly how mine worked. It literally just pops up completely built. I popped it up and anchored it in under 1 minute and had it packed up within 1min. Aslo I purchased the 2 person version and it's huge on the inside once it's up. I'm 5”10 and could sleep 4 or 5 of me in the tent. Not sure if they sent me a 4 person on accident but it doesn't seem to be the case."
                                    </p>


                                </div>


                            </div>

                        </div>

                    </div>

                    <div className='blog-right-div'>
                        <div className='bottom-padding'>
                            <h2 style={{ display: 'flex', justifyContent: 'center', fontSize: '30px' }}>
                                You Might Also Like
                            </h2>
                            <hr
                                style={{

                                    background: 'black',
                                    color: 'black',
                                    borderColor: 'black',
                                    height: '1px',
                                    borderRadius: '15px',
                                    marginLeft: '10vw',
                                    marginRight: '10vw',
                                    marginBottom: '40px'
                                }}
                            />
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-8-camping-backpacks"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={backpack} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 8 Backpacks For Camping
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 26th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/best-shoes-for-hikers"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={shoes} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Essential Shoe Recommendations for Hikers
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 25th 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-5-axes-for-camping"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={axe} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 5 Axes for Camping
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            March 16th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/top-5-suggestions-for-winter-jackets-for-men"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={jacket} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 5 Suggestions For Winter Jackets For Men
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 22nd 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                        </div>




                    </div>

                </div>

            </div>
            <BottomNavBar></BottomNavBar>
        </div>



    )
}

export default below100;