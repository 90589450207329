import "./BottomBlogs.css"
import React from 'react'
import waterbottle from "../blogs/best-insulated-water-bottles-for-camping/back.jpg"
import knives from "../blogs/best-knives-for-mountain-trips/back.jpg"
import below100 from "../blogs/best-camping-tents-below-100/back.jpg"
import jacket from "../blogs/top-5-suggestions-for-winter-jackets/winterjacket.jpg"
import backpack from "../blogs/best-backpacks-for-camping/back.jpg"
import shoes from "../blogs/best-shoes-for-hikers/back.jpg"
import { Link } from "react-router-dom"
export default function BottomBlogS() {
    return (
        <div className="main">
            <div className="main-div">
                <div className="row-div">
                    <Link to={"/best-insulated-water-bottles-for-camping"} >
                        <div className="bottom-blogs-div">
                            <img className="bottom-photo-div" src={waterbottle} alt='img' height={'100%'} width={'100%'}></img>
                            <div className="bottom-h1-divs">
                                <h1 style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: '700', color: 'black' }}>

                                    Best Insulated Water Bottles for Camping
                                </h1>
                            </div>

                            <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                May 16th 2024
                            </b>
                        </div>

                    </Link>
                </div>

                <div className="row-div2">
                    <Link to={"/best-knives-for-mountain-trips"}>
                        <div className="bottom-blogs-div">
                            <img className="bottom-photo-div" src={knives} alt='img' height={'100%'} width={'100%'}></img>
                            <div className="bottom-h1-divs">
                                <h1 style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: '700', color: 'black' }}>
                                    The Ultimate Guide to Knives for Mountain Camping
                                </h1>
                            </div>



                            <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>
                                May 13th 2024
                            </b>
                        </div>

                    </Link>
                </div>




            </div>

            <div className="main-div2">
                <div className="row-div">

                    <Link to={"/top-8-camping-backpacks"} >
                        <div className="bottom-blogs-div">
                            <img className="bottom-photo-div" src={backpack} alt='img' height={'100%'} width={'100%'}></img>
                            <div className="bottom-h1-divs">
                                <h1 style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: '700', color: 'black' }}>

                                    Top 8 Backpacks For Camping
                                </h1>
                            </div>

                            <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                April 26th 2024
                            </b>
                        </div>

                    </Link>

                </div>

                <div className="row-div2">

                    <Link to={"/best-shoes-for-hikers"}>
                        <div className="bottom-blogs-div">
                            <img className="bottom-photo-div" src={shoes} alt='img' height={'100%'} width={'100%'}></img>
                            <div className="bottom-h1-divs">
                                <h1 style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: '700', color: 'black' }}>
                                    Essential Shoe Recommendations for Hikers
                                </h1>
                            </div>



                            <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>
                                April 25th 2024
                            </b>
                        </div>

                    </Link>
                </div>


            </div>

            <div className="main-div3">
                <div className="row-div">
                    <Link to={"/top-5-suggestions-for-winter-jackets-for-men"} >
                        <div className="bottom-blogs-div">
                            <img className="bottom-photo-div" src={jacket} alt='img' height={'100%'} width={'100%'}></img>
                            <div className="bottom-h1-divs">
                                <h1 style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: '700', color: 'black' }}>
                                    Top 5 Suggestions For Winter Jackets For Men
                                </h1>
                            </div>

                            <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>
                                April 22nd 2024
                            </b>
                        </div>


                    </Link>


                </div>

                <div className="row-div2">
                    <Link to={"/best-camping-tents-below-100"}>
                        <div className="bottom-blogs-div">
                            <img className="bottom-photo-div" src={below100} alt='img' height={'100%'} width={'100%'}></img>
                            <div className="bottom-h1-divs">
                                <h1 style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: '700', color: 'black' }}>
                                    Best Camping Tents Below $100
                                </h1>
                            </div>

                            <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>
                                April 21st 2024
                            </b>
                        </div>

                    </Link>


                </div>



            </div>



        </div>

    )


}