import React from 'react'
import Navbar from '../../components/Navbar';

import five from './5.jpg'
import four from './4.jpg'
import three from './3.jpg'
import two from './2.jpg'
import one from './1.jpg'
import '../Blogs-Template.css'
import BottomNavBar from '../../components/BottomNavBar';
import Background from './back.jpg'
import {Helmet} from "react-helmet";
import axe from "../best-axes-for-camping/axe.jpg"

import jacket from "../top-5-suggestions-for-winter-jackets/winterjacket.jpg"
import backpack from "../best-backpacks-for-camping/back.jpg"
import shoes from "../best-shoes-for-hikers/back.jpg"
import { Link } from "react-router-dom";

const bestKnives = ({ title, description, canonicalUrl }) => {
    return (
        <div className='template-overflow'>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div id='pop-bar'>
                <Navbar />
            </div>
            <div className='blog-main-div'>

                <div className='blog-top-div'>
                    <div className='blog-left-div'>

                        <div className='blog-column-div1'>

                            <div className='zero-padding' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {/* BLOG BAŞLIĞI */}
                                <h2 className='template-header'>
                                    Cutting Edge: The Ultimate Guide to Knives for Mountain Camping
                                </h2>
                                {/* BLOG TARIHI */}
                                <p className='template-p-times' style={{ fontSize: '22px', fontWeight: '600' }}>
                                    May 13th 2024
                                </p>
                                <img alt='images' style={{ objectFit: 'contain' }} src={Background} width={'100%'} height={'100%'} ></img>
                                <p className='template-p-times'>
                                    By promoting our products, we may earn a commission through our affiliate programs for each sale.
                                </p>
                                {/* BLOG GİRİŞ PARAGRAFI */}
                                <p className='paragraphs'>
                                    We all realize that having a knife of good quality always brings comfort and easiness to your camping trip. I am a knife enthusiastic myself. That being said, I am more than thrilled to prepare this blog for all those camping fanciers. A knife can be extremely dangerous in wrong hands and one cannot be too careful while dealing with a sharp tool such as camping knives. A knife should only be used in required circumstances, some like kitchen or camping areas. Regardless, we hope that this mountain knives blog entry can lead you to a good purchase that you will be more than happy to use your product. Stay safe! 
                                </p>

                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        5) Ka-Bar Becker BK9
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={five} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Class: Hunting
                                                </li>
                                                <li className='spec-text'>
                                                    Model Name: EK50
                                                </li>
                                                <li className='spec-text'>
                                                    Handle Material: Alloy Steel
                                                </li>
                                                <li className='spec-text'>
                                                    Blade Material: 1095 Cro-Van
                                                </li>

                                                <li className='spec-text'>
                                                    Style: Modern
                                                </li>
                                                <li className='spec-text' >
                                                    Blade Length: 5.06 Inches(12.7 cm)
                                                </li>
                                                <li className='spec-text'>
                                                    Made in USA
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Special KA-BAR Steel "1095 CRO-VAN" 
                                            </li>
                                            <li className='list-padding'>
                                                Special Feature: Full Tang
                                            </li>
                                            <li className='list-padding'>
                                                Flat Grind Design
                                            </li>
                                            <li className='list-padding'>
                                                MOLLE Compatible Celcon Sheath
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Considerably Small
                                            </li>
                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/4bf9Bvn'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $112.49 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                    The Ka-Bar EK Short Clip Point Fixed Blade is a versatile tool suitable for various outdoor activities, particularly hunting. Its 5.0625-inch 1095 CroVan steel blade offers durability and excellent cutting performance. The full tang design ensures strength and stability during heavy use. With a modern and sleek black finish, it exudes style while maintaining functionality. The inclusion of a MOLLE compatible Celcon sheath enhances portability and accessibility, making it an ideal companion for outdoor adventures. Whether you're skinning game or tackling campsite tasks, this knife delivers reliability and precision.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "This knife is a great back up knife. John knew what he was creating. All EK KNIVES are the best. That's all I carry. No other knives will do. It's the truth!"
                                    </p>


                                </div>

                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        4) Mossy Oak Survival Hunting Knife
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={four} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Class: Camping, Military
                                                </li>
                                                <li className='spec-text'>
                                                    Brand: Mossy Oak
                                                </li>
                                                <li className='spec-text'>
                                                   Blade Material: Stainless Steel
                                                </li>
                                                <li className='spec-text'>
                                                    Handle Material: Rubber
                                                </li>
                                                <li className='spec-text'>
                                                    Blade Lenght: 10 Inches(25.4cm)
                                                </li>
                                                <li className='spec-text'>
                                                    Blade Shape: Clip Point
                                                </li>
                                                <li className='spec-text'>
                                                    Weight: 16.8 Ounces(453 gram)
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                High-end Stainless Steel & Blade Coating
                                            </li>
                                            <li className='list-padding'>
                                                Comfortable Rubber Grip
                                            </li>
                                            <li className='list-padding'>
                                                Magnesium Fire Starter
                                            </li>
                                            <li className='list-padding'>
                                                Sharp Edge in 10 Seconds
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Extra Weight
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3JZIYOR'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $19.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                    The Mossy Oak Survival Hunting Knife is a robust tool designed for outdoor enthusiasts, offering reliability in demanding situations. With a 15-inch fixed blade, it exceeds the strength of typical hunting or Bowie knives. Crafted from stainless steel, the razor-sharp clip point blade boasts excellent hardness, edge retention, and resistance to rust, making it ideal for camping expeditions, survival training, or military use. The rubber handle ensures a secure and comfortable non-slip grip, while the heavy-duty nylon sheath provides convenient and safe carrying options. Additionally, the knife comes with a sharpener and fire starter, tucked away for outdoor convenience. Whether you're a hunter, camper, or survivalist, the Mossy Oak knife is built to endure the rigors of outdoor adventures.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "This product stands out! It was exactly as described in the description upon arrival. It is sturdy and solid and very sharp. The knife sharpener and fire starter (included) works great! It is excellent for the price and great for outdoors and a nice addition if you are a collector"
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        3) Smith & Wesson Extreme Ops Folding Knife
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={three} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Class: Everday Carry
                                                </li>
                                                <li className='spec-text'>
                                                    Brand: Smith & Wesson
                                                </li>
                                                <li className='spec-text'>
                                                    Model Name: SWA24S
                                                </li>
                                                <li className='spec-text'>
                                                    Handle Material: Alluminium
                                                </li>
                                                <li className='spec-text'>
                                                    Blade Material: High Carbon Stainless Steel
                                                </li>
                                                <li className='spec-text' >
                                                    7.1 Inch(17.8 cm)
                                                </li>
                                                <li className='spec-text'>
                                                    Weight: 3.5 Ounce(100 gram)
                                                </li>

                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Special Feature: Lightweight
                                            </li>
                                            <li className='list-padding'>
                                                Comfortable Grip
                                            </li>
                                            <li className='list-padding'>
                                                Convenient Size
                                            </li>
                                            <li className='list-padding'>
                                                Easy Open
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Stiff Hinge
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3QL8X0l'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $10.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                    The Smith & Wesson Extreme Ops Folding Knife is a sleek and dependable tool designed for everyday carry. With a 3.1-inch black oxide high carbon stainless steel blade, it offers durability and reliability in various tasks. The lightweight aluminum handle ensures comfortable handling, while features like the ambidextrous thumb knobs, finger flipper, and pocket clip provide quick and easy access. The liner lock mechanism ensures blade security, giving you confidence in its stability during use. Whether you need to tackle everyday tasks or require a reliable backup tool, this knife offers functionality and style. Plus, backed by a Limited Lifetime Warranty, it's a purchase you can trust.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "3.5 ons kaç gramIt's a nice folding knife came sharp as a razor. I like the serrations on the back half of blade, it will cut rope much better than a straight knife. The blade is a bit hard to open with one hand but it can be done."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        2) Gerber Gear Ultimate Survival Knife
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={two} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Class: Hunting, Survival
                                                </li>
                                                <li className='spec-text'>
                                                    Brand: Gerber
                                                </li>
                                                <li className='spec-text'>
                                                    Model Name: Ultimate Survival Knife
                                                </li>
                                                <li className='spec-text'>
                                                    Blade Material: Stainless Steel
                                                </li>

                                                <li className='spec-text'>
                                                    Handle Material: Rubber
                                                </li>
                                                <li className='spec-text' >
                                                    Fixed Blade
                                                </li>
                                                <li className='spec-text'>
                                                    Weight: 312.41 Grams
                                                </li>
                                                <li className='spec-text'>
                                                    4.8 Inch(12.20 cm)
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Convenient Size
                                            </li>
                                            <li className='list-padding'>
                                                Special Feature: Non-Slip, Durable
                                            </li>
                                            <li className='list-padding'>
                                                Fire Starter
                                            </li>
                                            <li className='list-padding'>
                                                Emergency Whistle 
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Fire Starter Problems
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3UwUPsI'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $42.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        The Gerber Gear Ultimate Survival Knife is a versatile and reliable tool designed to meet the demands of survival and outdoor adventures. With its half-serrated, drop point stainless steel blade and textured rubber grip, it offers durability and precise handling in various situations. The inclusion of features like the stainless steel hammer pommel, emergency whistle, sharpener, and fire starter enhances its functionality and usefulness in survival scenarios. The durable sheath, complete with a sharpening stone and ferrocerium rod, ensures easy and safe storage and access to essential tools. Whether you're a hunter, tradesman, or outdoor enthusiast, this knife is a valuable addition to your gear arsenal. Its convenient size, backed by the Gerber Guarantee, makes it a trustworthy companion for any adventure.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Great knife, I also have the Bear Grylls version that I found at a pawn shop. I lost the fire starting ferro rod on my other knife, it fell out of the retainer. I don't like that it's faced down when wearing the knife in the sheath on my belt, if the ferro rod loosens a little or snags on something then gravity makes sure it falls out."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        1) CIVIVI Mini Praxis Folding Pocket Knife
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={one} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Class: Camping, Hiking
                                                </li>
                                                <li className='spec-text'>
                                                    Brand: CIVIVI
                                                </li>
                                                <li className='spec-text'>
                                                    Model Name: C18026C-1
                                                </li>
                                                <li className='spec-text'>
                                                    Handle Material: Fiberglass
                                                </li>

                                                <li className='spec-text'>
                                                    Blade Material: D2
                                                </li>
                                                <li className='spec-text' >
                                                    6.8 Inches(12.2cm)
                                                </li>
                                                <li className='spec-text'>
                                                    Weight: 4.1 ounce(113 gram)
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Special Feature: Foldable
                                            </li>
                                            <li className='list-padding'>
                                                Comfort Grip
                                            </li>
                                            <li className='list-padding'>
                                                USA SERVICE CENTER
                                            </li>
                                            <li className='list-padding'>
                                                Easy to Carry 
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Stiff Lock
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3ynuExm'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $29.75 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                    The CIVIVI Mini Praxis Folding Pocket Knife is a compact yet powerful tool designed for everyday carry, camping, and hiking adventures. Crafted from black stonewashed D2 steel, it offers excellent edge retention, corrosion resistance, and ease of sharpening, ensuring its reliability in various conditions. The G10 handle provides a comfortable and secure grip, even in challenging environments. Its ceramic ball-bearing pivot construction enables smooth and fast deployment with just one hand, while the strong detent ensures long-lasting crisp action. Versatile in its applications, from urban tasks to outdoor excursions, this knife is perfect for everyday use. Whether you're opening boxes, tackling outdoor tasks, or simply need a reliable EDC companion, the CIVIVI Mini Praxis delivers. Its compact size makes it convenient to carry in a pocket, purse, or backpack, while its durability ensures it can handle whatever challenges come your way. Plus, with CIVIVI's lifetime service against defects in material and workmanship, you can trust that this knife will be by your side for years to come.


                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "An all-around excellent daily carry drop point flipper knife, that I just wish was a touch bigger."
                                    </p>


                                </div>
                                
                            </div>

                        </div>

                    </div>

                    <div className='blog-right-div'>
                        <div className='bottom-padding'>
                            <h2 style={{ display: 'flex', justifyContent: 'center', fontSize: '30px' }}>
                                You Might Also Like
                            </h2>
                            <hr
                                style={{

                                    background: 'black',
                                    color: 'black',
                                    borderColor: 'black',
                                    height: '1px',
                                    borderRadius: '15px',
                                    marginLeft: '10vw',
                                    marginRight: '10vw',
                                    marginBottom: '40px'
                                }}
                            />
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-8-camping-backpacks"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={backpack} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 8 Backpacks For Camping
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 26th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/best-shoes-for-hikers"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={shoes} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Essential Shoe Recommendations for Hikers
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 25th 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-5-axes-for-camping"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={axe} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 5 Axes for Camping
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            March 16th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/top-5-suggestions-for-winter-jackets-for-men"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={jacket} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 5 Suggestions For Winter Jackets For Men
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 22nd 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                        </div>




                    </div>

                </div>

            </div>
            <BottomNavBar></BottomNavBar>
        </div>



    )
}

export default bestKnives;