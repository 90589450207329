
import Pagination from '../components/pagination';
import './AllBlogs.css'
import React, { useState } from "react";
import Navbar from '../components/Navbar';
import BottomNavBar from '../components/BottomNavBar';
import winter from "../blogs/best-tents-for-winter/winter.jpg"
import axe from "../blogs/best-axes-for-camping/axe.jpg"
import below100 from "../blogs/best-camping-tents-below-100/back.jpg"
import jacket from "../blogs/top-5-suggestions-for-winter-jackets/winterjacket.jpg"
import backpack from "../blogs/best-backpacks-for-camping/back.jpg"
import shoes from "../blogs/best-shoes-for-hikers/back.jpg"
import waterbottle from "../blogs/best-insulated-water-bottles-for-camping/back.jpg"
import knives from "../blogs/best-knives-for-mountain-trips/back.jpg"
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const AllBlogs = ({ title, description, canonicalUrl }) => {
  const people = ["Bob", "Lisa", "Anika", "Obi", "Sara"];
  const pageLimit = 2;
  const [pagePeople, setPagePeople] = useState([]);
  return (

    <div className='all-blog-div'>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div id='pop-bar'>
        <Navbar />
      </div>
      <div style={{ paddingTop: '100px' }}>
        <div className='all-blog-font'>
          <h1 style={{ color: 'black', padding: '15px' }} className='font'>
            ALL BLOGS
          </h1>
        </div>

        <hr className='hr'
          style={{
            marginTop: '30px',

            marginLeft: '35%',
            marginRight: '35%',
            background: 'black',
            color: 'black',
            borderColor: 'black',
            height: '2px',
            borderRadius: '15px'
          }}
        />
      </div>
      <div style={{ paddingBottom: '60px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

        <div className='all-blogs-divs'>
          <div className='all-blogs-div-mobile'>
            <Link to={"/best-insulated-water-bottles-for-camping"}>
              <img alt='images' style={{ objectFit: 'contain' }} src={waterbottle} width={'100%'} height={'50%'} ></img>
              <h1 style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'center' }} className='paragraphs'>
                Best Insulated Water Bottles for Camping
              </h1>
              <b className='date-font' style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '10px', color: 'black' }}>
                May 16th 2024
              </b>
            </Link>

          </div>
          <div className='all-blogs-div-mobile'>
            <Link to={"/best-knives-for-mountain-trips"}>
              <img alt='images' style={{ objectFit: 'contain' }} src={knives} width={'100%'} height={'50%'} ></img>
              <h1 style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'center' }} className='paragraphs'>
                The Ultimate Guide to Knives for Mountain Camping
              </h1>
              <b className='date-font' style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '10px', color: 'black' }}>
                May 13th 2024
              </b>
            </Link>

          </div>
          <div className='all-blogs-div-mobile'>
            <Link to={"/top-8-camping-backpacks"}>
              <img alt='images' style={{ objectFit: 'contain' }} src={backpack} width={'100%'} height={'50%'} ></img>
              <h1 style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'center' }} className='paragraphs'>
                Top 8 Backpacks For Camping
              </h1>
              <b className='date-font' style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '10px', color: 'black' }}>
                April 26th 2024
              </b>
            </Link>

          </div>
        </div>
        <div className='all-blogs-divs'>

          <div className='all-blogs-div-mobile'>
            <Link to={"/best-shoes-for-hikers"}>
              <img alt='images' style={{ objectFit: 'contain' }} src={shoes} width={'100%'} height={'50%'} ></img>
              <h1 style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'center' }} className='paragraphs'>
                Essential Shoe Recommendations for Hikers
              </h1>
              <b className='date-font' style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '10px', color: 'black' }}>
                April 25th 2024
              </b>
            </Link>
          </div>
          <div className='all-blogs-div-mobile'>
            <Link to={"/top-5-suggestions-for-winter-jackets-for-men"}>
              <img alt='images' style={{ objectFit: 'contain' }} src={jacket} width={'100%'} height={'50%'} ></img>
              <h1 style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'center' }} className='paragraphs'>
                Top 5 Suggestions For Winter Jackets For Men
              </h1>
              <b className='date-font' style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '10px', color: 'black' }}>
                April 22nd 2024
              </b>
            </Link>
          </div>
          <div className='all-blogs-div-mobile'>
            <Link to={"/best-camping-tents-below-100"}>
              <img alt='images' style={{ objectFit: 'contain' }} src={below100} width={'100%'} height={'50%'} ></img>
              <h1 style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'center' }} className='paragraphs'>
                Best Camping Tents Below $100
              </h1>
              <b className='date-font' style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '10px', color: 'black' }}>
                April 21st 2024
              </b>
            </Link>

          </div>
        </div>
        <div className='all-blogs-divs'>

          <div className='all-blogs-div-mobile'>
            <Link to={"/top-10-tents-for-winter-camping"}>
              <img alt='images' style={{ objectFit: 'contain' }} src={winter} width={'100%'} height={'50%'} ></img>
              <h1 style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'center' }} className='paragraphs'>
                Top 10 Camping Tents for Winter Trips
              </h1>
              <b className='date-font' style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '10px', color: 'black' }}>
                March 16th 2024
              </b>
            </Link>
          </div>
          <div className='all-blogs-div-mobile'>
            <Link to={"/top-5-axes-for-camping"}>
              <img alt='images' style={{ objectFit: 'contain' }} src={axe} width={'100%'} height={'50%'} ></img>
              <h1 style={{ width: '100%', color: 'black', display: 'flex', justifyContent: 'center' }} className='paragraphs'>
                Top 5 Axes for Camping
              </h1>
              <b className='date-font' style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '10px', color: 'black' }}>
                March 16th 2024
              </b>
            </Link>


          </div>

        </div>

      </div>

      <div style={{ paddingBottom: '50px' }}>

        <Pagination
          items={people}
          pageLimit={pageLimit}
          setPageItems={setPagePeople}
        />

      </div>

      <BottomNavBar></BottomNavBar>
    </div>
  )
};

export default AllBlogs;