import React, { useState } from "react";
import "./SearchBar.css";
import { Link } from "react-router-dom"
import search from "../search.svg"
function SearchBar({ placeholder, data }) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = data.filter((value) => {
            return value.name.toLowerCase().includes(searchWord.toLowerCase());
        });

        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };

    return (
        <div className="search">
            <div className="searchInputs">
                <div style={{display:'flex',alignItems:'center'}}>
                    <img height={'35px'} width={'35px'} src={search} alt="images" />
                </div>
                
                <input
                    type="text"
                    placeholder={placeholder}
                    value={wordEntered}
                    onChange={handleFilter}
                    style={{color:'white'}}
                    
                />
                

            </div>
            <hr className="search-line"
                  style={{
                    backgroundColor: 'white',
                    border: 'white',
                    height: 1,
                    
                    paddingRight: '60vw',
                    
                  }}
                />
            {filteredData.length !== 0 && (
                <div className="dataResult">
                    {filteredData.map((value, key) => {
                        return (
                            <div>
                                <Link className="dataItem" to={value.link}  >

                                    <p>{value.name}

                                    </p>
                                </Link>
                                <div style={{ borderTop: "1px solid #3C633E " }}></div>
                            </div>



                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default SearchBar;