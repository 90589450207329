import BottomNavBar from "../components/BottomNavBar";
import Navbar from "../components/Navbar";
import tree from "../tree.jpg"
import tree2 from "../tree2.jpg"
import "./About-Us.css"

const Aboutus = () => {
    return (
        <div className="about-us-main">
            <div id='pop-bar'>
                <Navbar />
            </div>
            <div className="about-us-second" >
                <div className="about-us-img-div">
                    <img src={tree} width={'100%'} height={'100%'} className="about-us-img">
                    </img>
                </div>
                <div className="about-us-paragraph-div">
                    <b className="about-us-header">
                        ABOUT US
                    </b>
                    <p className="about-us-paragraphs">
                        Greetings and welcome to GearUp Frontier, the place to visit for in-depth gear recommendations and reviews. GearUp Frontier is dedicated to enabling outdoor enthusiasts to make well-informed gear selections by providing them with a variety of information.

                        At GearUp Frontier, we are aware that having the appropriate equipment can significantly enhance your outdoor experience. For this reason, we're committed to giving you dependable, in-depth reviews so you may prepare for your next trip and enjoy it with confidence.
                    </p>
                </div>
            </div>
            <div className="about-us-third">
                <div className="about-us-paragraph-div">
                    <b className="about-us-header">
                        WHAT WE DO
                    </b>
                    <p className="about-us-paragraphs">
                        Our goal is to examine a wide range of outdoor goods, including backpacks, portable stoves, hiking boots, camping tents, and more, honestly and objectively. GearUp Frontier is here to assist you in finding the ideal equipment to meet your needs and tastes, regardless of your level of experience as an outdoor lover or your level of adventure.

                        GearUp Frontier is your go-to source for anything gear-related, whether you're organizing a multi-day hiking excursion, a weekend camping trip, or an amazing backpacking journey. So come along with us as we investigate the cutting edge of outdoor gear, and let's gear up for adventure!
                    </p>
                </div>
                <div className="about-us-img-div">
                    <img src={tree2} width={'100%'} height={'100%'} className="about-us-img2">
                    </img>
                </div>

            </div>
            <BottomNavBar></BottomNavBar>

        </div>
    )

};

export default Aboutus;