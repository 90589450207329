import React from 'react';
import "./Navbar.css"
import icon from "../icon.png"
import menu from "../menu.svg"
import close from "../close.svg"
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import data from '../Data.json'

import bottomimgmobile from "../bottomimg.png"
export default function Navbar() {
  const [index, setIndex] = React.useState(0);

  /*
  window.addEventListener('resize', function (event) {
    if (window.screen.width > 600) {

      document.getElementById("close-div").style.visibility = 'hidden'
      document.getElementById("navbar-responsive").style.visibility = 'hidden'
    }
    if (window.screen.width <= 600) {

      document.getElementById("navbar-responsive").style.visibility = 'visible'
    }
  }, true);
*/
  return (

    <div className={`navbar sticky-nav`}>

      <div className='top-row'>

        <div id='navbar-responsive' onClick={() => {
          if (window.screen.width <= 1200) {
            setIndex(1);
            document.getElementById("root").style.overflowY = 'hidden'
            document.getElementById("menu-bar").style.transform = `translate3d(${300}vh, 0, 0)`
            document.getElementById("pop-bar").style.background = 'rgba(0,0,0,0.5)'
            document.getElementById("pop-bar").style.position = 'fixed'
            document.getElementById("pop-bar").style.left = '0'
            document.getElementById("pop-bar").style.right = '0'
            document.getElementById("pop-bar").style.bottom = '0'
            document.getElementById("pop-bar").style.top = '0'
            document.getElementById("pop-bar").style.zIndex = '5000'


            document.getElementById("close-div").style.visibility = 'visible'
            document.getElementById("navbar-responsive").style.visibility = 'hidden'
          } else {

            document.getElementById("close-div").style.visibility = 'hidden'
            document.getElementById("navbar-responsive").style.visibility = 'hidden'
          }



        }} >
          <img src={menu} height={30} width={30} alt='menu' />
        </div>
        <div id={'menu-bar'}>

          <div id='close-div' >

            <img src={close} height={30} width={30} alt='menu' onClick={() => {
              if (window.screen.width <= 1200) {
                setIndex(0);
                document.getElementById("root").style.overflowY = 'visible'
                document.getElementById("menu-bar").style.transform = `translate3d(${-300}vh, 0, 0)`
                document.getElementById("navbar-responsive").style.visibility = 'visible'
                document.getElementById("close-div").style.visibility = 'hidden'
                document.getElementById("pop-bar").style.background = ''

                document.getElementById("pop-bar").style.position = ''
                document.getElementById("pop-bar").style.left = ''
                document.getElementById("pop-bar").style.right = ''
                document.getElementById("pop-bar").style.bottom = ''
                document.getElementById("pop-bar").style.top = ''
                document.getElementById("pop-bar").style.zIndex = ''
              } else {

                document.getElementById("navbar-responsive").style.visibility = 'hidden'
                document.getElementById("close-div").style.visibility = 'hidden'
              }


            }} />
          </div>
          <div style={{paddingTop:'20px'}}>
            <SearchBar placeholder="Search Blogs..." data={data} />
          </div>

          <div>
            <div id='navbar-column'>

              <Link to="/" style={{ fontWeight: 'bolder', color: 'white', textDecoration: 'none' }} >
                <b className="top-text">Home</b>
                <hr
                  style={{
                    backgroundColor: 'white',
                    border: 'white',
                    height: 1,
                    
                    paddingRight: '60vw',
                    
                  }}
                />
              </Link>

              <Link to="/all-blogs" style={{ fontWeight: 'bolder', color: 'white', textDecoration: 'none' }} >
                <b className="top-text">All Blogs</b>
                <hr
                  style={{
                    backgroundColor: 'white',
                    border: 'white',
                    height: 1,
                    
                    paddingRight: '60vw',
                    
                  }}
                />
              </Link>

              <Link to="/about-us" style={{ fontWeight: 'bolder', color: 'white', textDecoration: 'none' }} >
                <b className="top-text">About Us</b>
                <hr
                  style={{
                    backgroundColor: 'white',
                    border: 'white',
                    height: 1,
                    
                    paddingRight: '60vw',
                    
                  }}
                />
              </Link>

              {/* <SearchBar placeholder="Search Blogs..." data={data} />  */}


            </div>
            <div style={{ display: 'flex', width: '100%',justifyContent:'center' }}>
              <Link to="/" >
                <img className='navbar-icon-image2' src={bottomimgmobile} alt='images' />
              </Link>

            </div>
          </div>

        </div>


        <div className='image-div'>
          <Link to="/" >
            <img className='navbar-icon-image' src={icon} alt='images' />
          </Link>

        </div>

        <div className='search-div'>
            <SearchBar placeholder="Search Blogs..." data={data} />
          </div>

        <div className='text-row'>
          <Link to="/" style={{ fontWeight: 'bolder', color: 'white', textDecoration: 'none', marginRight: '5vh' }} >
            <b className="top-text">Home</b>
          </Link>
          <Link to="/all-blogs" style={{ fontWeight: 'bolder', color: 'white', textDecoration: 'none', marginRight: '5vh' }} >
            <b className="top-text">All Blogs</b>
          </Link>

          <Link to="/about-us" style={{ fontWeight: 'bolder', color: 'white', textDecoration: 'none', marginRight: '5vh' }} >
            <b className="top-text">About Us</b>
          </Link>


        </div>





      </div>



    </div>)
};