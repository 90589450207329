import React from 'react'
import Navbar from '../../components/Navbar';

import five from './5.jpg'
import four from './4.jpg'
import three from './3.jpg'
import two from './2.jpg'
import one from './1.jpg'
import '../Blogs-Template.css'
import BottomNavBar from '../../components/BottomNavBar';
import Background from './axe.jpg'
import { Helmet } from "react-helmet";
import winter from "../best-tents-for-winter/winter.jpg"

import below100 from "../best-camping-tents-below-100/back.jpg"
import jacket from "../top-5-suggestions-for-winter-jackets/winterjacket.jpg"
import backpack from "../best-backpacks-for-camping/back.jpg"

import { Link } from "react-router-dom";
const bestAxes = ({ title, description, canonicalUrl }) => {
    return (

        <div className='template-overflow'>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div id='pop-bar'>
                <Navbar />
            </div>
            <div className='blog-main-div'>

                <div className='blog-top-div'>
                    <div className='blog-left-div'>

                        <div className='blog-column-div1'>

                            <div className='zero-padding' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {/* BLOG BAŞLIĞI */}
                                <h2 className='template-header'>
                                    Top 5 Axes for Camping
                                </h2>
                                {/* BLOG TARIHI */}
                                <p className='template-p-times' style={{ fontSize: '22px', fontWeight: '600' }}>
                                    March 16th 2024
                                </p>
                                <img alt='images' style={{ objectFit: 'contain' }} src={Background} width={'100%'} height={'100%'} ></img>
                                <p className='template-p-times'>
                                    *By promoting our products, we may earn a commission through our affiliate programs for each sale.
                                </p>
                                {/* BLOG GİRİŞ PARAGRAFI */}
                                <p className='paragraphs'>
                                    When it comes to camping, having the right tools can make all the difference. One of the most essential tools for any camping trip is a good camping axe. A camping axe not only helps you cut firewood but can also assist in various other tasks, such as clearing the campsite or building a shelter. However, not all camping axes are created equal. Understanding the importance of a good camping axe and knowing what key features to look for can help ensure that you choose the best one for your needs. A camping axe is not just any ordinary tool; it is a versatile companion that can assist you in multiple ways during your camping adventure. From splitting logs for a cozy campfire to preparing firewood for cooking, a camping axe plays a crucial role in making your camping experience more enjoyable and convenient. It also comes in handy for pruning branches, clearing pathways, and even self-defense in a worst-case scenario
                                </p>

                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        5) Fiskars X25 Splitting Axe
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={five} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    28" handle
                                                </li>
                                                <li className='spec-text'>
                                                    Hardened forged steel blade
                                                </li>
                                                <li className='spec-text'>
                                                    6.19 pounds weight
                                                </li>
                                                <li className='spec-text'>
                                                    31.5 x 9.25 x 2 inches
                                                </li>
                                                <li className='spec-text'>
                                                    Country of origin is China
                                                </li>




                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Perfect for medium to large size logs
                                            </li>
                                            <li className='list-padding'>
                                                Shock-absorbing handle
                                            </li>
                                            <li className='list-padding'>
                                                28" handle is ideal for taller users
                                            </li>
                                            <li className='list-padding'>
                                                Hardened forged steel blade stays sharper longer than traditional axes
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Too head-heavy for one-hand use
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3UK87TV'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $43.00 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Sharp blade axe is an ideal wood chopper designed for firewood, yard work, and lawn cleanup, with maximum efficiency for more one-strike splits; Perfect for medium to large size logs Shock-absorbing handle with textured non-slip grip reduces hand strain, improves control, and prevents overstrike damage; 28" handle is ideal for taller users Hardened forged steel blade stays sharper longer than traditional axes and advanced bevel convex design splits efficiently and makes the blade easier to remove from wood; Provides excellent durability and lasting value
                                    </p>
                                    <h1 className='product-header'>
                                        Customer's Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "The good points, this axe splits wood! It is sharp and the weight distribution is amazing. After my first 30 min trial run when I got it was fun. I did not want to quit but it got to dark to keep cutting. This axe is everything they say it is. It makes lighter work of the heavy task of splitting rounds 24"-36" in diameter.
                                        Now the only bad point for me, a tingling in my left thumb. I noticed it after the first time and just shook it off as getting used to a new axe. No big deal.
                                        The second time I used it for over 2 hours. I split a lot of wood. Wearing gloves with it was not workable. It caused a pinching and began to rub a blister on my left thumb. I took the gloves off and kept going as this axe makes splitting wood as much fun as it can be splitting wood. The tingling in my thumb began to grow. It feels like just the last joint in my thumb is asleep. Now two days later the tingling is still there, but dimishing."
                                    </p>


                                </div>

                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        4) Morakniv Boron Steel Camping Axe
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={four} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    12.6"L x 4.5"W
                                                </li>
                                                <li className='spec-text'>
                                                    Leather,Plastic,Steel handle materials
                                                </li>
                                                <li className='spec-text'>
                                                    Orange color
                                                </li>
                                                <li className='spec-text'>
                                                    Hatchet head type
                                                </li>


                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Practical camping axe with head of black epoxy coated boron steel
                                            </li>
                                            <li className='list-padding'>
                                                Reinforced plastic handle
                                            </li>
                                            <li className='list-padding'>
                                                Leather sheath
                                            </li>
                                            <li className='list-padding'>
                                                Limited lifetime manufacturer's warranty.
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Back of the blade is pretty thin compared to other axes and isn't great for use as a hammer
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/4doEcrE'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $48.95 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Blade Thickness: 0.24" (6 mm), Blade Length: 4.5" (115 mm), Total Length: 12.6" (320 mm), Net Weight: 17.6 oz. (0.5 kg)
                                    </p>
                                    <h1 className='product-header'>
                                        Customer's Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "I've used this for a few months and I can say I'm thoroughly impressed. The handle is strong and ergonomic. The edge on the blade came sharp and has help up well. I've only had to touch up the edge a few times after relatively heavy use. I will eventually have to put a new edge on it but that doesn't seem to be anytime soon. The sheath it comes with could be better but for the price it's not that bad. After all, you're buying this product for the axe and the sheath is secondary. Some complain about the odd steel but I've had no issues with chipping or corrosion. Like I stated before, the edge has held extremely well. Just another great Morakniv product. Maybe offer a few other colors for the handle down the road? At this point I'm really reaching for cons. Lastly, it should be noted that the back of the blade is pretty thin compared to other axes and isn't great for use as a hammer or driving in tent stakes. However, you can just turn the axe to its side and use the side of the handle (near the head of the axe) to drive in the stakes. I wouldn't recommend any heavy duty strikes with this method"
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        3) WilFiks Chopping Axe
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={three} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Camping Outdoor Hatchet for Wood Splitting and Kindling
                                                </li>
                                                <li className='spec-text'>
                                                    Forged Carbon Steel Heat Treated Hand Maul Tool
                                                </li>
                                                <li className='spec-text'>
                                                    Fiberglass Shock Reduction Handle with Anti-Slip Grip
                                                </li>
                                                <li className='spec-text'>
                                                    26"L x 6.5"W
                                                </li>
                                                <li className='spec-text'>
                                                    3.99 pounds
                                                </li>



                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Forged Carbon Steel Blade
                                            </li>
                                            <li className='list-padding'>
                                                Ergonomic Grip
                                            </li>
                                            <li className='list-padding'>
                                                Hanging Hole

                                            </li>
                                            <li className='list-padding'>
                                                Plastic Protective Cover

                                            </li>




                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                The cover for the blade is poor quality
                                            </li>
                                            <li className='list-padding'>
                                                Dull but After sharping pretty good
                                            </li>



                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/4aSp7wZ'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $31.49 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Our Wood Chopping Axe is designed for easy chopping Of Firewood, Logs, Kindling and Branches. The Optimized blade geometry is designed for maximum efficiency to give you more one-strike splits. Ideal for campers, hikers, outdoor activities, preparing wood for bonfires and garden work. Our Hatchet has a Forged Carbon Steel Heat Treated blade which improves its density and makes it more durable and produces smooth, sharp and quick splits. You can count on this Felling Axe to stay sharp much longer than traditional axes which will deliver superior and long lasting performance!
                                    </p>
                                    <h1 className='product-header'>
                                        Customer's Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "I purchased this axe primarily to chop out roots that remained from larger bushes I removed. It was a tough job for a smaller ax being some were emended in dirt and there were some small rocks. This ax worked perfectly. It was well made, perfectly sized for close quarter work, had some heft to it, had a comfortable non slip grip, and was ergonomically designed. I received the blade in a very sharp condition. The head looked like it was very well secured to the handle. I anticipate that it will easily sharpen when it's needed due to the material that the head is made from. Very happy with my purchase. Looks like it will last."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        2) KSEIBI Wood Axe
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={two} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    36"L x 7.99"W
                                                </li>
                                                <li className='spec-text'>
                                                    Fiberglass handle
                                                </li>
                                                <li className='spec-text'>
                                                    36-inch Chopping Axe
                                                </li>
                                                <li className='spec-text'>
                                                    Blade Length is 8 Inches
                                                </li>
                                                <li className='spec-text'>
                                                    5.64 pounds
                                                </li>


                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Lightweight, well made and very sharp when delivered
                                            </li>
                                            <li className='list-padding'>
                                                Ergonomic handle
                                            </li>
                                            <li className='list-padding'>
                                                Great price
                                            </li>
                                            <li className='list-padding'>
                                                Lite and sturdy
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                The handle is a little light
                                            </li>


                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3WsQae4'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $36.15 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        The splitting hatchet blade made from high forged steel and heat-treated gives you maximum efficiency and durability to split or chop wood logs in campfire camping, hiking, Wetterling, and outdoor activities. Our wood kindling axe comes with a lightweight 14" overall length handle giving you a perfect balance and power, to increase swing speed and multiply chopping power.
                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "This hatchet is perfect for gobag kit. It is very sharp, with an ergonomic handle. Great price, everyone thinks I paid $50 for it.
                                        If there is anything at all that I could complain about it would be the handle is a little light.
                                        Overall the perfect hatchet."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        1) INTERTOOL 28” Chopping Axe
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={one} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Handle material: Polyamide, Fiberglass, and Thermoplastic Rubber
                                                </li>
                                                <li className='spec-text'>
                                                    Type: Felling Axe
                                                </li>
                                                <li className='spec-text'>
                                                    Head material: Carbon Steel
                                                </li>
                                                <li className='spec-text'>
                                                    Total length: 28.3 inches
                                                </li>

                                                <li className='spec-text'>
                                                    Handle length: 25.6 inches
                                                </li>
                                                <li className='spec-text'>
                                                    Blade width: 3.5 inches
                                                </li>
                                                <li className='spec-text'>
                                                    Total weight: 4.1 lbs
                                                </li>

                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Good value for the money
                                            </li>
                                            <li className='list-padding'>
                                                Well balanced
                                            </li>
                                            <li className='list-padding'>
                                                Perfect for chopping
                                            </li>
                                            <li className='list-padding'>
                                                Nice safe edge guard
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Could be sharper
                                            </li>


                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3y4pwOk'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $44.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Ultra-light profile and sharp advanced blade outperforms traditional axes in weight and cutting power. Features an easy-carry sheath and hang holes for secure storage, pack or vehicle attachment, and increased safety. Only 4 pounds. Heavy-duty carbon steel blade coated in non-stick material for deeper cuts, one-strike splits and is resistant to corrosion, rust, and wear. Composite handle dampens shock from each strike and fits comfortably in hand. Ergonomic non-slip rubber grip provides a confident and secure grip. Perfect for a range of outdoor activities, including camping, hiking, and yardwork or landscaping. Designed to efficiently chop and split firewood, trees, kindling, and branches.
                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Nice big axe!! Item as described. well balanced, well built (from the logs of it) sharp blade!! Great value! half the price of competitors. I will be trying this out tomorrow, if it doesn't function well, I will change my review, it it functions great I will leave this review up!"
                                    </p>


                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='blog-right-div'>
                        <div className='bottom-padding'>
                            <h2 style={{ display: 'flex', justifyContent: 'center', fontSize: '30px' }}>
                                You Might Also Like
                            </h2>
                            <hr
                                style={{

                                    background: 'black',
                                    color: 'black',
                                    borderColor: 'black',
                                    height: '1px',
                                    borderRadius: '15px',
                                    marginLeft: '10vw',
                                    marginRight: '10vw',
                                    marginBottom: '40px'
                                }}
                            />
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-8-camping-backpacks"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={backpack} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 8 Backpacks For Camping
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 26th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/best-camping-tents-below-100"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={below100} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Best Camping Tents Below $100
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 21st 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-10-tents-for-winter-camping"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={winter} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 10 Camping Tents for Winter Trips
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            March 16th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/top-5-suggestions-for-winter-jackets-for-men"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={jacket} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 5 Suggestions For Winter Jackets For Men
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 22nd 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                        </div>




                    </div>

                </div>

            </div>
            <BottomNavBar></BottomNavBar>
        </div>



    )
}

export default bestAxes;