import React from 'react'
import Navbar from '../../components/Navbar';
import five from './5.jpg'
import four from './4.jpg'
import three from './3.jpg'
import two from './2.jpg'
import one from './1.jpg'
import '../Blogs-Template.css'
import BottomNavBar from '../../components/BottomNavBar';
import Background from './winterjacket.jpg'
import winter from "../best-tents-for-winter/winter.jpg"
import { Helmet } from "react-helmet";
import below100 from "../best-camping-tents-below-100/back.jpg"

import backpack from "../best-backpacks-for-camping/back.jpg"
import shoes from "../best-shoes-for-hikers/back.jpg"
import { Link } from "react-router-dom";
const winterjacket = ({ title, description, canonicalUrl }) => {
    return (
        <div className='template-overflow'>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div id='pop-bar'>
                <Navbar />
            </div>
            <div className='blog-main-div'>

                <div className='blog-top-div'>
                    <div className='blog-left-div'>

                        <div className='blog-column-div1'>

                            <div className='zero-padding' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <h2 className='template-header'>
                                    Top 5 Suggestions For Winter Jackets For Men
                                </h2>
                                <p className='template-p-times' style={{ fontSize: '22px', fontWeight: '600' }}>
                                    April 22nd 2024
                                </p>
                                <img alt='images' style={{ objectFit: 'contain' }} src={Background} width={'100%'} height={'100%'} ></img>
                                <p className='template-p-times'>
                                    *By promoting our products, we may earn a commission through our affiliate programs for each sale.
                                </p>
                                <p className='paragraphs'>
                                    Heading into the great outdoors requires the right gear, and a good jacket is essential. It's your versatile shield against the elements, from chilly mornings to breezy nights. Here are the top five camping jackets that blend durability, functionality, and style to keep you comfortable during your outdoor adventures.
                                </p>

                                <div className='blog-column-div'>
                                    <h3 className='product-header'>
                                        5) BIYLACLESEN Men's Winter Fleece Jackets
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'scale-down' }} src={five} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Polyester
                                                </li>
                                                <li className='spec-text'>
                                                    Hand Wash Only
                                                </li>
                                                <li className='spec-text'>
                                                    2 big Slant zipper pockets
                                                </li>
                                                <li className='spec-text'>
                                                    3 arm zipper pockets for Stowing small thing
                                                </li>
                                                <li className='spec-text'>
                                                    2 back zipper pocket and 2 deep inner pocket
                                                </li>

                                                <li className='spec-text' >
                                                    Stand collar and Hooded,front zip-up closure, thumb hole,more durable zipper.
                                                </li>
                                            </div>

                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            <li className='list-padding'>
                                                Easy to clean
                                            </li>
                                            <li className='list-padding'>
                                                Very warm
                                            </li>
                                            <li className='list-padding'>
                                                Comfortable
                                            </li>

                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            <li className='list-padding'>
                                                Not for backpacking
                                            </li>

                                        </div>

                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3wjSCIW'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $45.98 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>


                                    <p className='paragraphs'>
                                        Soft and Warm Tactical Fleece Jackets to Keep You Athletic and Comfortable in the Cold Outdoors.Winter Jacket with Standing Collar and Full Zipper Closed Design; Arm Patch; Elastic Cuff, Sealed in Warmth.This Tactical Jacket Has Six Zippered Safety Pockets to Keep Your Belongings Safe. Two Side Pockets, Two Pockets on Both Arms, One Zipper Pocket on the Chest and One Inner Pocket.Suitable for Tactics, Hiking, Hunting, Fishing, Camping, Training, Climbing and Outdoor Sports.
                                    </p>
                                    <h1>
                                        Customers Review
                                    </h1>
                                    <p className='paragraphs'>
                                        "I've worn this fleece jacket all winter in the Dakota's. It's a great second or first layer. Easy to clean and the jacket has a very thick layer of fleece. I've worn it in 18 degree weather and was still warm but that's pushing it a bit as it's not windproof. One of the softest jackets I have that's also warm . It also has what seems like a dozen pockets and zippers.
                                        I'm getting another that's one size bigger so I can use layers underneath the jacket."
                                    </p>


                                </div>

                                <div className='blog-column-div'>
                                    <h3 className='product-header'>
                                        4)Pioneer Camp Softshell Jacket for Men
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'scale-down' }} src={four} width={'50%'} height={'50%'} ></img>
                                    </div>
                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    92% Polyester, 8% Spandex
                                                </li>
                                                <li className='spec-text'>
                                                    Hand wash only
                                                </li>
                                                <li className='spec-text'>
                                                    High-quality soft shell fabric
                                                </li>
                                                <li className='spec-text'>
                                                    4-way Stretch allows a wide range of motion
                                                </li>


                                                <li className='spec-text'>
                                                    Waterproof
                                                </li>
                                                <li className='spec-text' >
                                                    Windproof
                                                </li>
                                                <li className='spec-text'>
                                                    7 Practical Pockets
                                                </li>
                                            </div>

                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            <li className='list-padding'>
                                                Comfortable in a wide Temperature range
                                            </li>
                                            <li className='list-padding'>
                                                Best value in a Coat/Jacket
                                            </li>
                                            <li className='list-padding'>
                                                So easy to walk around with
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            <li className='list-padding'>
                                                A little tight
                                            </li>
                                        </div>

                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3JKr4j5'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $46.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>


                                    <p className='paragraphs'>
                                        This men's hooded softshell jacket is made of high-quality soft shell fabric,4-way stretch allows a wide range of motion, which is very soft to the touch. At the same time, this garment also considers warmth retention, and the fleece lining allows you to still be free outdoors in autumn and winter On the go, it's a great jacket for outdoor.This rain jacket for men is made of waterproof treatment 8000 mmH2o, breathable 5000g/m2/24h, which can effectively waterproof and keep breathable, so that you can still stay dry and comfortable even in the rainy, snowy and wet environment .This tactical jackets for men has 7 practical pockets. 2 front zipped hand warmer pockets, 1 chest zipped pocket. 4 lined pockets ideal for money, keys, cell phone, wallet, passport, flashlight,credict card and more.
                                    </p>
                                    <h1>
                                        Customers Review
                                    </h1>
                                    <p className='paragraphs'>
                                        "I have been wearing this jacket regularly this winter, and it continues to shine in the wind, rain, and even in the light snow fall I encountered. I am able to stay warm and dry, and the velcro around the wrists helps tremendously. I have to wear braces on my hands and I can do so comfortably with this jacket. I love the hood that is attached, and the fit is spot on. I don't feel it is baggy or too loose. I can easily access my PDW/CCW and draw and move without resistance. The jacket is definitely meeting and exceeding my expectations. I also like that the company name and logo is subdued, so it doesn't standout."
                                    </p>
                                </div>
                                <div className='blog-column-div'>
                                    <h3 className='product-header'>
                                        3)ReFire Gear Men's Army Special Ops Military Tactical Jacket
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'scale-down' }} src={three} width={'50%'} height={'50%'} ></img>
                                    </div>
                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    100% polyester soft shell fabric, with thermal fleece liner
                                                </li>
                                                <li className='spec-text'>
                                                    Hand Wash Only
                                                </li>
                                                <li className='spec-text'>
                                                    2 large zippered chest pockets
                                                </li>
                                                <li className='spec-text'>
                                                    2 back pockets
                                                </li>
                                                <li className='spec-text'>
                                                    Great for outdoor sport, hunting, fishing, hiking, climbing, camping, travelling, motorcycle, cycling, paintball, airsoft shooting and casual wear
                                                </li>


                                                <li className='spec-text'>
                                                    2 small pockets on left arm and 1 small pockets on left sleeve
                                                </li>
                                                <li className='spec-text' >
                                                    Good for winter, autumn and spring
                                                </li>
                                            </div>


                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            <li className='list-padding'>
                                                The parka is lightweight with fleece lining that will keep you warm
                                            </li>
                                            <li className='list-padding'>
                                                Low price
                                            </li>
                                            <li className='list-padding'>
                                                Waterproof & Windproof
                                            </li>
                                            <li className='list-padding'>
                                                Tactical multi-Pockets design
                                            </li>
                                        </div>
                                        <div>
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            <li className='list-padding'>
                                                Cheap zippers
                                            </li>
                                        </div>

                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/4ajPCdP'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $49.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>


                                    <p className='paragraphs'>
                                        Men's Clothes Zipper; Huge hood can be roll up; Two way zip up to open or close; Under arm vent zippers; Hook & loop adjustable wrist cuff; Drawstring waist and hood; Large loop on both arm for individuality patches. 2 large zippered chest pockets; 2 back pockets; 2 small pockets on left arm and 1 small pockets on left sleeve
                                    </p>
                                    <h1>
                                        Customers Review
                                    </h1>
                                    <p className='paragraphs'>
                                        "This is my second purchase - one black and one blue, both great colors. Bought 1 XL and one L . Both fit well. For size comparison I am 6-01, 190 lbs. The price for this well made jacket is unbelievable! Terrific well made softshell parka. The parka is lightweight with fleece lining that will keep you warm. The hood has cord adjusts for a proper fit and can be folded up and stowed if desired. Numerous pockets throughout and underarm vent zips. You cannot go wrong with this $50.00 jacket and is true to size. The lining should keep you warm into the 50s. For more warmth, consider layering underneath, such as a Magcomsen Puffer Jacket. The combined jackets will easily keep you comfortable below freezing."
                                    </p>
                                </div>
                                <div className='blog-column-div'>
                                    <h3 className='product-header'>
                                        2)Pioneer Camp Mens Rain Jacket
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'scale-down' }} src={two} width={'50%'} height={'50%'} ></img>
                                    </div>
                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    100% Polyester
                                                </li>
                                                <li className='spec-text'>
                                                    long battery life
                                                </li>
                                                <li className='spec-text'>
                                                    Machine Wash
                                                </li>
                                                <li className='spec-text'>
                                                    Country of Origin is China
                                                </li>


                                                <li className='spec-text'>
                                                    Waterproof & Windproof
                                                </li>
                                                <li className='spec-text' >
                                                    Breathable & Comfortable
                                                </li>
                                                <li className='spec-text'>
                                                    YKK zipper & 4 Pockets
                                                </li>
                                                <li className='spec-text'>
                                                    Handy Features
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            <li className='list-padding'>
                                                Rain jacket with a really sturdy shell
                                            </li>
                                            <li className='list-padding'>
                                                Very well made, stylish, sturdy rain jacket
                                            </li>
                                            <li className='list-padding'>
                                                The zippers are smooth
                                            </li>


                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            <li className='list-padding'>
                                                Doesn't move well with wider shoulders and larger arms.
                                            </li>
                                        </div>

                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3WmADw3'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $68.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>


                                    <p className='paragraphs'>
                                        Fully seam sealed. 100% polyester canvas with PU Films laminate and durable water-repellent (DWR) finish. 100% polyester lining 10,000MM fabric waterproof rating, keeping you dry and comfortable no matter the heavy rain or other weather conditions. 8,000G/M2/24HR fabric breathability rating. The mens rain jacket with hood allows excess heat and moisture to escape, keeping you cool and dry. The front YKK zipper design is easy to put on and off. Men's raincoat with 2 side zippered pockets deep enough for 6.7" phone, 1 zippered chest pocket and 1 Interior zippered pocket to keep personal stuff safe. Adjustable hood with locking draw cord, adjustable velcro cuffs, and interior drawcord hem to seal out rain and cold air effectively. Its timeless design features clean lines and a classic silhouette, making it the perfect addition to any outfit for any occasion.
                                    </p>
                                    <h1>
                                        Customers Review
                                    </h1>
                                    <p className='paragraphs'>
                                        "Very sturdy outer shell. Think of a rain jacket with a really sturdy shell. Its basically a softshell rain jacket. Dont expect warmth (because there's basically nothing inside), expect a very well made, stylish, sturdy rain jacket. I personally have never really experienced anything like it. I've owned rain jackets, and softshell jackets, but this is something else. I like it ALOT... It looks GREAT!!! As for 66 dollars? Nope. I dont think its worth that. Around $50 to $55 is about right. If it had an actual liner in it, that might justify the $66 price tag. But its literally a beautiful hollow shell (Oh and... The zippers are smooth and work better than most reputable name brands)... I'm keeping it! (Oh and... If your order a 2X, your gonna get a 2X. They're not being stingy with the sizing)"
                                    </p>
                                </div>
                                <div className='blog-column-div'>
                                    <h3 className='product-header'>
                                        1)33,000ft Men's Hooded Softshell Jacket
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'scale-down' }} src={one} width={'50%'} height={'50%'} ></img>
                                    </div>
                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Shell: 96% polyester and 4% spandex;
                                                </li>
                                                <li className='spec-text'>
                                                    Lining: 100% polyester
                                                </li>
                                                <li className='spec-text'>
                                                    Machine Wash
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof & Breathable
                                                </li>


                                                <li className='spec-text'>
                                                    Warm Fleece Lined Jacket
                                                </li>
                                                <li className='spec-text' >
                                                    Windproof & Lightweight
                                                </li>
                                                <li className='spec-text'>
                                                    Stretch & Comfortable
                                                </li>
                                                <li className='spec-text'>
                                                    5 Functional Utility Pockets
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            <li className='list-padding'>
                                                3-layered material with outer soft shell
                                            </li>
                                            <li className='list-padding'>
                                                TPU membrane mid-layer and inner fleece lining to provide strong protection against the elements
                                            </li>
                                            <li className='list-padding'>
                                                Outer shell with DWR finish resists water, dirt and oil to keep you dry and clean. (8000MM fabric waterproofness rating, 1000G/M2/24hr fabric breathability rating)
                                            </li>
                                            <li className='list-padding'>
                                                Outer zipper pockets keep hands warm
                                            </li>



                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            <li className='list-padding'>
                                                Thinner than expected
                                            </li>
                                            <li className='list-padding'>
                                                Runs small in sizes
                                            </li>

                                        </div>

                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3WmASHt'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $59.99 - $64.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>


                                    <p className='paragraphs'>
                                        33,000ft men's softshell jacket with hood features 3-layered material with outer soft shell, TPU membrane mid-layer and inner fleece lining to provide strong protection against the elements. Outer shell with DWR finish resists water, dirt and oil to keep you dry and clean. (8000MM fabric waterproofness rating, 1000G/M2/24hr fabric breathability rating)33,000ft men's softshell jacket with hood features 3-layered material with outer soft shell, TPU membrane mid-layer and inner fleece lining to provide strong protection against the elements. Outer shell with DWR finish resists water, dirt and oil to keep you dry and clean. (8000MM fabric waterproofness rating, 1000G/M2/24hr fabric breathability rating) 33,000ft men's fleece lined soft shell jacket gives you additional thermal insulation and superior moisture-wicking performance. The brushed fleece lining is soft to the touch and keeps you comfortable and cozy. Featuring adjustable drawcord hood & hem and ribbed cuffs blocking out the cold & wind, this 33,000ft lightweight lined windbreaker with hood is great to be your all weather go-to jacket for spring and fall when the temperature changes. 2 outside hand zippered pockets, 2 inner patch pockets and 1 left arm zip pocket are perfect for storing your cell phone, wallet, gloves etc. This 33,000ft men's jacket with hood is suitable for casual wear or outdoor activities like mountaineering, climbing, riding, biking, fishing, yardwork, walking, backpacking, military, combat, tactical training, cycling, hunting, driving, travelling, work, camping etc.
                                    </p>
                                    <h1>
                                        Customers Review
                                    </h1>
                                    <p className='paragraphs'>
                                        "One of the best jackets I've ever bought. It's perfect for cold weather and windy days. You can feel how the material is specifically designed to not let the chilly air get to you. On top of that it's warm and fuzzy on the inside and best of all it's stylish and can be worn as a daily jacket. What I love is its versatility in that it won't overheat you on warmer days but it also won't leave you cold on cold days. One thing to note is to size up. I'm usually a medium and I bought large. Fit is perfect 10/10"
                                    </p>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='blog-right-div'>
                        <div className='bottom-padding'>
                            <h2 style={{ display: 'flex', justifyContent: 'center', fontSize: '30px' }}>
                                You Might Also Like
                            </h2>
                            <hr
                                style={{

                                    background: 'black',
                                    color: 'black',
                                    borderColor: 'black',
                                    height: '1px',
                                    borderRadius: '15px',
                                    marginLeft: '10vw',
                                    marginRight: '10vw',
                                    marginBottom: '40px'
                                }}
                            />
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-8-camping-backpacks"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={backpack} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 8 Backpacks For Camping
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 26th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/best-camping-tents-below-100"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={below100} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Best Camping Tents Below $100
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 21st 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-10-tents-for-winter-camping"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={winter} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 10 Camping Tents for Winter Trips
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            March 16th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/best-shoes-for-hikers"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={shoes} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Essential Shoe Recommendations for Hikers
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 25th 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                        </div>




                    </div>

                </div>

            </div>
            <BottomNavBar></BottomNavBar>
        </div>



    )
}

export default winterjacket;