import React from 'react'
import Navbar from '../components/Navbar';

import './Privacy-Policy.css'
import BottomNavBar from '../components/BottomNavBar';

const PrivacyPolicy = () => {
    return (

        <div >
            <div id='pop-bar'>
                <Navbar />
            </div>
            <div style={{ backgroundColor: '#E6EDF2' }}>
                <div className='privacy-policy-main'>
                    <h2 className='privacy-policy-h-font'>
                        Privacy policy
                    </h2>
                    <p className='privacy-policy-p-font'>
                        Gear Up Frontier is fully committed to protecting your personal information as we recognize how important it can be for you. When you visit our blog site, we collect, utilize, and protect your data according to the rules in our Privacy Policy.



                    </p>

                    <p className='privacy-policy-p-font'>
                        You agree the terms stated in this Privacy Policy by using Gear Up Frontier and its services. This policy may be updated from time to time, and it is your obligation to examine the most recent version.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        Data That We Gather:
                    </p>
                    <p className='privacy-policy-p-font'>


                        <span style={{ fontStyle: 'italic', fontWeight: '700' }}>Log Data: </span>  When you visit Gear Up Frontier, your browser sends information to us, just like it does to many other websites. Your IP address, browser type, pages viewed, and timestamps may be included in this.


                    </p>
                    <p className='privacy-policy-p-font'>
                        <span style={{ fontStyle: 'italic', fontWeight: '700' }}>Cookies: </span> We use cookies to track website traffic and improve your surfing experience. Cookies are minimal files that are kept on your device and give us the ability to identify your preferences and personalize your experience.</p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>


                        How Your Information Is Used by Us:
                    </p>
                    <p className='privacy-policy-p-font'>


                        <span style={{ fontStyle: 'italic', fontWeight: '700' }}>Personalization: </span> By displaying relevant material and adverts, we make use of the information we collect to customize your experience on Gear Up Frontier.
                    </p>
                    <p className='privacy-policy-p-font'>


                        <span style={{ fontStyle: 'italic', fontWeight: '700' }}>Analytics: </span> We can track user behavior, evaluate trends, and enhance the functionality and content of our website with the use of the data we collect.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>

                        Disclosure and Information Exchange:
                    </p>
                    <p className='privacy-policy-p-font'>


                        <span style={{ fontStyle: 'italic', fontWeight: '700' }}>Third-Party Service Providers: </span> In order to help us run Gear Up Frontier and provide you with services, we could disclose your information to dependable third-party service providers. These suppliers have an obligation to protect the privacy and security of your information.
                    </p>
                    <p className='privacy-policy-p-font'>


                        <span style={{ fontStyle: 'italic', fontWeight: '700' }}>Legal Compliance: </span> In accordance with applicable laws and in response to legitimate court orders or subpoenas, we may divulge personal information.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>


                        Information Security:
                    </p>
                    <p className='privacy-policy-p-font'>


                        We place a high priority on the security of your data and take the necessary precautions to guard against unauthorized access, alteration, disclosure, and destruction.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>


                        Assent:
                    </p>
                    <p className='privacy-policy-p-font'>


                        You agree that Gear Up Frontier may collect, use, and disclose your information in the ways outlined in this privacy policy by using the service.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>


                        Get in touch with us:
                    </p>
                    <p className='privacy-policy-p-font'>


                        Please email us at contact@gearupfrontier.com with any queries or worries you may have regarding this privacy statement or our data collection procedures.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>


                        The last modification to this policy was made on March 14, 2024.
                    </p>
                </div>
            </div>

            <BottomNavBar></BottomNavBar>
        </div>

    )
}

export default PrivacyPolicy;