import React from 'react'
import Navbar from '../components/Navbar';
import Slider from '../components/Slider'
import Background from '../home.avif'
import BottomBlogs from '../components/BottomBlogs';
import {Helmet} from "react-helmet";
import "./home.css"
import BottomNavBar from '../components/BottomNavBar';
const Home = ({ title, description, canonicalUrl }) => {
  return (<div className='text'>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
    <div id='pop-bar'>
      <Navbar />
    </div>

    <div className='home_div'>
      <img alt='images' style={{ boxShadow: '0px 10px 20px 10px #818181' }} className='home_background' src={Background} height={'400px'} width={'100%'} />
    </div>

    
    <div className='font-div'>
      <h1 style={{ color: 'black', padding: '15px' }} className='font'>
        TRENDING
      </h1>
    </div>

    <hr className='hr'
      style={{
        marginTop: '30px',
        marginBottom: '20px',
        marginLeft: '35%',
        marginRight: '35%',
        background: 'black',
        color: 'black',
        borderColor: 'black',
        height: '2px',
        borderRadius: '15px'
      }}
    />
    <Slider />
    <hr className="bottom-blogs-line"
      style={{
        backgroundColor: 'black',
        border: 'black',
        height: 1,
        marginBottom: '30px',
        paddingRight: '90vw',

      }}
    />
    <div style={{ paddingBottom: '30px' }}>
      <hr className='hr'
        style={{

          marginBottom: '30px',
          marginLeft: '150px',
          marginRight: '150px',
          background: 'black',
          color: 'black',
          borderColor: 'black',
          height: '2px',
          borderRadius: '15px'
        }}
      />
      <div className='font-div'>
        <h1 style={{ color: 'black', padding: '15px' }} className='font'>
          LATEST BLOGS
        </h1>
      </div>
      <hr className='hr'
        style={{
          marginTop: '30px',
          marginBottom: '20px',
          marginLeft: '35%',
          marginRight: '35%',
          background: 'black',
          color: 'black',
          borderColor: 'black',
          height: '1px',
          borderRadius: '15px'
        }}
      />
      <BottomBlogs></BottomBlogs>
    </div>





    <BottomNavBar></BottomNavBar>


  </div>
  )
};

export default Home;