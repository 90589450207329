import React from 'react';
import "./Slider.css"

import waterbottle from "../blogs/best-insulated-water-bottles-for-camping/back.jpg"
import knives from "../blogs/best-knives-for-mountain-trips/back.jpg"
import shoes from "../blogs/best-shoes-for-hikers/back.jpg"
import left_arrow from "../left_arrow.svg"
import right_arrow from "../right_arrow.svg"
import { Link } from 'react-router-dom';

const slider_photos = [waterbottle,knives,shoes];


const blogName = ["best-insulated-water-bottles-for-camping","best-knives-for-mountain-trips","best-shoes-for-hikers"]
const delay = 3500;

export default function Slideshow() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }


  const [touchPosition, setTouchPosition] = React.useState(null)

  const handleTouchStart = (e) => {
      const touchDown = e.touches[0].clientX
      setTouchPosition(touchDown)
  }
  
  const handleTouchMove = (e) => {
    const touchDown = touchPosition
  
    if(touchDown === null) {
        return
    }
  
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
  
    if (diff > 5) {
      setIndex((prevIndex) => {
        if (index === slider_photos.length - 1){
          return index
        }else {
          return prevIndex === slider_photos.length - 1 ? slider_photos.length - 2 : prevIndex + 1
        }
        
      }
      
    )
    }
  
    if (diff < -5) {
      setIndex((prevIndex) => {
        if (index === 0){
          return index
        }else {
          return prevIndex === slider_photos.length - 1 ? slider_photos.length - 2 : prevIndex - 1
        }
      }
      
    )
    }
  
    setTouchPosition(null)
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === slider_photos.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className='arrow'>
      
        <div className='outerLeft'>
          <div className='arrow2'>
            
            <img className='img' onClick={() => {
                if(index === 0){

                }else {
                  setIndex(index - 1);
                }
                
              }}  src={left_arrow} alt='img' height={'15%'} width={'100%'}></img>
          </div>
        </div>
          
          
        
        
      <div className="slideshow" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
        
        
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          
          {slider_photos.map((image, index) => (
            
            <div
              className="slide"
              key={index}
            
            >
              <Link to={"/" + blogName[index]} >
              <img className='img2' src={image} alt='img' height={'100%'} width={'100%'}></img>
              </Link>
              
              
            
            

            </div>
          ))}
        </div>

        <div className="slideshowDots">
          {slider_photos.map((_, idx) => (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>
      </div>
        <div className='outerRight'>
          <div className='arrow3'>
            <img className='img' onClick={() => {
                if(slider_photos.length -1 === index){

                }else {
                  setIndex(index + 1);
                }
                
              }} src={right_arrow} alt='img' height={'15%'} width={'100%'}></img>
          </div>
        </div>
          
    </div>
  );
}