import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import './pagination.css'
var max_page_number = 2
const pages = [1]



const Pagination = () => {
    const [pageNumber, setPageNumber] = useState(1);
    function nextClick() {
        if (max_page_number !== pageNumber + 1) {

            setPageNumber(pageNumber + 1)
        }

    }
    function previousClick() {
        if (pageNumber !== 0) {


            setPageNumber(pageNumber - 1)
        }

    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

            <div
                className={`prev${pageNumber !== 1 ? "" : "disabled"}`}
                style={{ marginLeft: '10px', marginRight: '10px' ,border: '1px solid #969696'}}
                onClick={previousClick}
            >
                <a href="#" className={`prev${pageNumber !== 1 ? "" : "disabled-a"}`} style={{ textDecoration: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '65px', height: '40px'}}>
                        <b style={{ display: 'flex', alignItems: 'center' }} >Prev</b>
                    </div>

                </a>



            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div className="pagination-row">
                    <div  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#FFFDFA' }}>
                            <a href="#" className="number-font">
                                <div onClick={() => {
                                    setPageNumber(1);
                                }} className={`pagination-dots${pageNumber === 1 ? " active" : ""}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '35px', height: '40px', border: '1px solid #969696' }}>
                                    <div>
                                        <b>
                                            {1}
                                        </b>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        
                    </div>

                    

                </div>
            </div>






            <div
                className={`next${max_page_number !== pageNumber + 1 ? "" : "disabled"}`}
                style={{ marginLeft: '10px', marginRight: '10px',border: '1px solid #969696' }}
                onClick={nextClick}
            >
                <a href="#" className={`next${max_page_number !== pageNumber + 1 ? "" : "disabled-a"}`} style={{ textDecoration: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '65px', height: '40px'}}>
                        <b style={{ display: 'flex', alignItems: 'center' }} >Next</b>
                    </div>

                </a>



            </div>



        </div>
    );
};

export default Pagination;