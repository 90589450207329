import React from 'react'
import Navbar from '../../components/Navbar';
import ten from './10.jpg'
import nine from './9.jpg'
import eight from './8.jpg'
import seven from './7.jpg'
import six from './6.jpg'
import five from './5.jpg'
import four from './4.jpg'
import three from './3.jpg'
import two from './2.jpg'
import one from './1.jpg'
import '../Blogs-Template.css'
import BottomNavBar from '../../components/BottomNavBar';
import Background from './winter.jpg'
import {Helmet} from "react-helmet";
import axe from "../best-axes-for-camping/axe.jpg"
import below100 from "../best-camping-tents-below-100/back.jpg"

import backpack from "../best-backpacks-for-camping/back.jpg"
import shoes from "../best-shoes-for-hikers/back.jpg"
import { Link } from "react-router-dom";

const bestWinter = ({ title, description, canonicalUrl }) => {
    return (
        <div className='template-overflow'>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div id='pop-bar'>
                <Navbar />
            </div>
            <div className='blog-main-div'>

                <div className='blog-top-div'>
                    <div className='blog-left-div'>

                        <div className='blog-column-div1'>

                            <div className='zero-padding' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {/* BLOG BAŞLIĞI */}
                                <h2 className='template-header'>
                                    Top 10 Camping Tents for Winter Trips
                                </h2>
                                {/* BLOG TARIHI */}
                                <p className='template-p-times' style={{ fontSize: '22px', fontWeight: '600' }}>
                                    March 16th 2024
                                </p>
                                <img alt='images' style={{ objectFit: 'contain' }} src={Background} width={'100%'} height={'100%'} ></img>
                                <p className='template-p-times'>
                                    *By promoting our products, we may earn a commission through our affiliate programs for each sale.
                                </p>
                                {/* BLOG GİRİŞ PARAGRAFI */}
                                <p className='paragraphs'>
                                    Winter camping can be a thrilling adventure, but it also requires careful preparation to ensure a comfortable and safe experience. One of the most crucial aspects of winter camping is having the right tent. A well-designed winter tent can protect you from chilly temperatures, strong winds, and heavy snowfall. In this article, we will explore the importance of a winter camping tent, key features to consider when buying one, review some top-rated options, discuss maintenance and care, and offer safety tips for winter camping.
                                </p>

                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        10) ForestDawn Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={ten} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    122.4"L x 85.5"W x 45.6"H
                                                </li>
                                                <li className='spec-text'>
                                                    2 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Weight about 7.6lbs (3.79 Kilograms)
                                                </li>
                                                <li className='spec-text'>
                                                    double layer mesh
                                                </li>

                                                <li className='spec-text'>
                                                    fabric 2 doors and 2 vestibules
                                                </li>
                                                <li className='spec-text' >
                                                    4 Seasons
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof
                                                </li>
                                                <li className='spec-text'>
                                                    Windproof
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Waterproof 4 Season 2 Person Tent
                                            </li>
                                            <li className='list-padding'>
                                                Durable Material
                                            </li>
                                            <li className='list-padding'>
                                                Instant Setup & Portable
                                            </li>
                                            <li className='list-padding'>
                                                Good Ventilation
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Only for 2 person
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/4bpNcv7'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                    $109.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        This tent's 3 poles structure is more stable and windproof than the two poles, which can effectively withstand harsh environments such as heavy rain, wind and snow. This tent built with rollable snow flaps around will keep inner dry and warm. Roomy space fits for 2 adults.
                                    </p>
                                    <h1 className='product-header'>
                                        Customer's Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "In four days at Caprock Canyons in Texas, we saw all the weather. Sun, rain, snow and insane wind. This 4-season tent held up like a real trooper. After a rainy first night (where we stayed dry in the tent), we went hiking while a cold front came in, along with sustained winds of 35mph - gusting to 60. I was sure we'd return to a collapsed tent or a big yellow kite. Nope. Held up great, and I was impressed.

                                        The poles are sturdy aluminum; outer tent guyline tieouts are really strong; and there are multiple options for ventilation. (Overnight condensation collected on the outer tent - not the inner one.) Dual vestibules are roomy enough for boots & gear. It is heavy; this wouldn't be a backpacking tent. But the compression stuff sack keeps everything compact and organized.

                                        I love this tent. The quality is ridiculously good at this price point."
                                    </p>


                                </div>

                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        9) Bessport Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={nine} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    104"L x 90"W x 43"H
                                                </li>
                                                <li className='spec-text'>
                                                    up to 2 Adult
                                                </li>
                                                <li className='spec-text'>
                                                    93 x 53 inches (228x135cm), interior height: 43.3 inch(110cm).
                                                </li>
                                                <li className='spec-text'>
                                                    Material Polyester
                                                </li>

                                                <li className='spec-text'>
                                                    Camping & Hiking
                                                </li>
                                                <li className='spec-text' >
                                                    Windproof
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof
                                                </li>
                                                <li className='spec-text'>
                                                    Lightweight
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Fully Seam Waterproof Windproof Tent
                                            </li>
                                            <li className='list-padding'>
                                                Durable Material With Polyester(190T polyester PU3000mm)
                                            </li>
                                            <li className='list-padding'>
                                                Instant Setup & Portable
                                            </li>
                                            <li className='list-padding'>
                                                Setup Easy & Smooth Zip
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Maximum height 110 Centimeters
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/4drcBGk'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $69.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        This backpacking tent is spacious enough with two D-Shaped doors and two vestibules to accommodate up to 2-3Adult. Weight: 5.5lb(2.5kg). Interior floor dimensions: 93 x 53 inches (228x135cm), interior height: 43.3 inch(110cm). Single Aluminum pole and clips structure make the tent easy to set up in virtually any weather, Snag-free. Welded smooth 2-way extra-large #8 zippers, never stuck, convenient to get in and out of the tent.
                                    </p>
                                    <h1 className='product-header'>
                                        Customer's Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "I used this on a 4 day trip during a tropical storm. This tent was amazing. I am very pleased with this purchase. I bought it for backpacking. It packs down small. I did buy the footprint that was made for the tent separately. Both are well worth the money. Seams are very well made. It didn't leak at all and it did very well in high winds. I felt very safe in the tent. It is extremely easy to set up. the different bags included with the tent make it very easy for setup. The instructions for the tent are sewn into the stuff sack. It does weigh a bit more than other backpacking tents but for the room it has and how it is built it is worth the extra weight in my opinion. It has held out for several trips now and is good for a lot more. The tent has a great design two vestibules and two doors to the tent made it easy to get in and out of the two person tent."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        8) FE Active Waterproof Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={eight} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    81.4"L x 55.1"W x 45.6"H
                                                </li>
                                                <li className='spec-text'>
                                                    2 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    4 Season
                                                </li>
                                                <li className='spec-text'>
                                                    2 Pounds Weight
                                                </li>

                                                <li className='spec-text'>
                                                    55.1 Inches floor width
                                                </li>
                                                <li className='spec-text' >
                                                    81.4 Inches floor length
                                                </li>
                                                <li className='spec-text'>
                                                    maximum height 45.6 Inches
                                                </li>
                                                <li className='spec-text'>
                                                    Polyurethane material
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Durability
                                            </li>
                                            <li className='list-padding'>
                                                Easy to attach to the bottom of your pack, and is not heavy.
                                            </li>

                                            <li className='list-padding'>
                                                Warm inside
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Low space
                                            </li>
                                            <li className='list-padding'>
                                                Bad Water resistence
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3y3sJ0E'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $89.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        210 Thread Ripstop Polyester with 30000mm PU Waterproof Coating POLES: 7.9mm Aluminum FLOOR: 210D Oxford Textile . The interior has 1 hook to hang a lantern or flashlight and two pockets to store your camping accessories, cell phone, camera equipment, or travel gear. Includes 12 upgraded aluminum stakes for 12 tie down points with 4 branded ropes on the tent to safely secure the tent to the ground during storms or high winds. Includes carry bag case with a rolltop entry with suppression buckles and a handle ideal for your hiking backpack. Pairs well with our camping cot and camping sleeping bags
                                    </p>
                                    <h1 className='product-header'>
                                        Customer's Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Bought this for backpacking in the winter, tested it in the back yard when we had a snowstorm.

                                        This is easy to attach to the bottom of your pack, and is not heavy.
                                        We did not find ourselves cold at all during the night.
                                        It did withstand the 3-4" of snow just fine.
                                        Super easy to set up! We actually set this up WHILE it was snowing and it only took a couple of minutes."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        7) Forceatt Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={seven} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    86.6"L x 51"W x 43.3"H
                                                </li>
                                                <li className='spec-text'>
                                                    2 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    4 Season
                                                </li>
                                                <li className='spec-text'>
                                                    2.63 Kilograms package weight
                                                </li>

                                                <li className='spec-text'>
                                                    Aluminum poles
                                                </li>
                                                <li className='spec-text' >
                                                    600 Mm water resistence
                                                </li>
                                                <li className='spec-text'>
                                                    43 Inches maximum height
                                                </li>
                                                <li className='spec-text'>
                                                    30.73 Square Feet floor area
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Very easy to set up and take down with one person.
                                            </li>
                                            <li className='list-padding'>
                                                The rain fly covers the tent and is well built
                                            </li>
                                            <li className='list-padding'>
                                                Size of the tent is large
                                            </li>
                                            <li className='list-padding'>
                                                Lightweight and convenient
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                The zippers are flimsy
                                            </li>
                                            <li className='list-padding'>
                                                Tent poles are aluminum poles
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3UJy9Xq'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $68.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Polyester rainfly&groundsheet&bathtub with PU3000-PU5000 coating is firmer than you expected. 7001 aluminum poles, iron ground stakes, and windproof rope guarantee the tent sturdy and difficult to deform even on windy or rainy days. Weatherproof material with Tape sealed seams keeps you dry and comfortable in all season&all weather condition!. To save your precious time and effort. Our four season tents can be assembled and disassembled quickly. It only takes 3 minutes to get a perfect personal space. And it can be dismantled in less than 2 minutes, thus getting rid of the trouble of traditional camping holidays.
                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Very easy to set up and take down with one person.
                                        The rain fly covers the tent and is well built. The seems on the inside of the fly are tape with laminate. The fly does cover the whole tent keeping it well protected.
                                        The two smaller cross poles help significantly with spacing and sturdiness. With the tent corners and fly staked down I used 12 tent stakes and that thing isn't going anywhere. I have not weathered a good storm but I feel as tho it will with stand any storm.
                                        Size of the tent is large for a 4 person. In the picture that's a queen size air mattress. There is plenty of room for it and other items.
                                        The quality of the bags impressed me. I feel you don't have to stuff and stress the bag as you do on older tent bags."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        6) OutdoorMaster Backpacking Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={six} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    84.5"L x 61"W x 58.2"H
                                                </li>
                                                <li className='spec-text'>
                                                    12.05 Pounds item weight
                                                </li>
                                                <li className='spec-text'>
                                                    The rainfly of the camping tent Constructed with 4 layers materials
                                                </li>
                                                <li className='spec-text'>
                                                    UV protection, lightweight, versatile design.
                                                </li>

                                                <li className='spec-text'>
                                                    Easy setup, designed for whole family.
                                                </li>
                                                <li className='spec-text' >
                                                    Oxford fabric type
                                                </li>
                                                <li className='spec-text'>
                                                    1500 Mm water resistence technology
                                                </li>
                                                <li className='spec-text'>
                                                    Windproof
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Perfect for 1-2 person
                                            </li>
                                            <li className='list-padding'>
                                                Durable Material
                                            </li>
                                            <li className='list-padding'>
                                                Instant Setup & Portable
                                            </li>
                                            <li className='list-padding'>
                                                Water-resistant bottom
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                The poles are a little on the cheap side
                                            </li>
                                            <li className='list-padding'>
                                                The floor material is a little on the thinner side
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3Ur66ux'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $99.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        This camping tent features a rainfly with a PU 5000mm coating and 210D Oxford fabric groundsheet, and precision-sealed seams to eliminating the possibility of water infiltration. This pop up tent ensures a dry and comfortable environment in all seasons and various weather conditions! This outstanding waterproof tent is the ideal choice for outdoor adventures and camping activities!
                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Pretty good tent, waterproof, mostly wind proof, definitely not 4 seasons as is.. it it gets any colder than 45 ish, you need insulation.. The rain fly extends all the way to the ground but is not weighted so it can get drafty.. it is a little small, two people will fit, but with very little room for gear, perfect for one person or one person with a dog.. I have a 110+ pound dog and it's just enough room for us a full size bed and some gear.. the floor material is a little on the thinner side, but has held up so far, but I would recommend a blanket at least for some insulation, it's like it absorbs the cold.. the poles are a little on the cheap side, but I've seen cheaper and it's held up through everything so far"
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        5) Clostnature Lightweight Backpacking Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={five} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    7.3"L x 4.11"W x 3.1"H
                                                </li>
                                                <li className='spec-text'>
                                                    2 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    Polyester, Aluminum, fabric materials
                                                </li>
                                                <li className='spec-text'>
                                                    Mesh&fabric double-layer doors
                                                </li>

                                                <li className='spec-text'>
                                                    Fabric 2 doors and 4 Mesh Windows
                                                </li>
                                                <li className='spec-text' >
                                                    4 Seasons
                                                </li>
                                                <li className='spec-text'>
                                                    2 D-shaped doors
                                                </li>
                                                <li className='spec-text'>
                                                    2 Vestibules
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Very easy setup
                                            </li>
                                            <li className='list-padding'>
                                                Perfect for two people
                                            </li>
                                            <li className='list-padding'>
                                                Small packable tent
                                            </li>
                                            <li className='list-padding'>
                                                Snow shield is perfect
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                It needs more interior storage
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/44oZ4Lo'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $79.99 - $108.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        We offer a limited ONE-YEAR WARRANTY. Polyester rain fly, groundsheet, and bathtub with PU 5000 coating are firmer than you expected. Weatherproof material with factory-sealed seams keeps you dry and comfortable in four seasons or cold weather! Better material but more affordable! You really need an appropriate lightweight 2-person tent for backpacking for ALL SEASON! Free-standing and two Aluminum poles designed for lightweight hiking tent's fast pitching. Easily set up by one person with no experience. This ultralight backpacking tent is compact and portable, and also great for Hiking, Backpacking, Kayaking, Mountaineering, Fishing, or Car Camping
                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "This tent is awesome. We used it for kayaking camping along the Current River this New Years eve weekend. Kept us warm and dry in 24 to 30 degree temps.. Very easy setup and perfect for two people. Put a tarp down under tent, used cots and insulated sleeping pads and our 10 degree mummy sleeping bags. This past weekend, we camped at Alley Spring and the snow flaps worked great deflecting snow to ground and keeping us dry. We couldn't be more pleased, we wish there was a larger version in the 4 season style. I went ahead and ordered a 4 person 3 season tent for the summer months. I highly recommend buying this tent. The tent is just right for two people, wish it was just a little bigger thopugh, with the outer tarp, you do have extra storage outside the tent."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        4) AYAMAYA Easy Pop Up Tents for Camping
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={four} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    Double Layer Waterproof Instant Tent
                                                </li>
                                                <li className='spec-text'>
                                                    4-6 People
                                                </li>
                                                <li className='spec-text'>
                                                    Waterproof 210D Oxford ground material
                                                </li>
                                                <li className='spec-text'>
                                                    2-layer Tent
                                                </li>

                                                <li className='spec-text'>
                                                    Good Ventilation
                                                </li>
                                                <li className='spec-text' >
                                                    3000MM Waterproof PU-Coated Polyester
                                                </li>


                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li>
                                                2 mesh windows on both sides, provide much better ventilation.
                                            </li>
                                            <li>
                                                Double-layer design has better water resistance and can stand up to inclement weather and elements better than a single-layer tent.
                                            </li>
                                            <li>
                                                A large front door and rear hooded vents provide airflow for cross ventilation and prevent condensation.
                                            </li>
                                            <li>
                                                Automatic instant setup.
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Very heavy
                                            </li>
                                            <li className='list-padding'>
                                                Collapsing it isn't super easy to get it back to its smallest size
                                            </li>
                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3JGDJUi'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $139.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Introducing the Ayamaya 6-person pop-up camping tent, designed to provide maximum comfort in the great outdoors and easy setup. This tent's pre-assembled poles allow it to quickly come to life, and its unique two-layer construction improves air circulation as it effectively lowers condensation. With an average height of 4.5 feet, it has a roomy inside that can easily fit 4-6 persons or 3-5 people with plenty of luggage. Its measurements are 12.5 x 8.5 feet. An additional vestibule offers more room for storing equipment or pets. With two doors, four mesh windows, and ground vents, enhanced ventilation is guaranteed to keep you comfortable on warm evenings and keep mosquitoes away. Plus, its waterproof Oxford fabric floor and PU3000 polyester roof, along with heat-sealed seams, guarantee protection from the elements, making it the perfect companion for any outdoor adventure.
                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Pretty sweet tent, very roomy, it can most definitely fit two people if they both slept on their own air mattress. More if they had just a single person mat. Lots of storage room! I recommend staking down your tent tight right away, as the wind can pick up the ends and blow in unwanted cold air at night. Great for keeping the bugs out with its double doorway design! It can get a little stuffy in warm weather, but its zippable windows do an alright job of moving air through the vestibule. I personally have not used my tent during any downpours but it has kept water out with no condensation on the inside. Pops up easy but its a little odd trying to fold it up, which is a given… I would say a few minutes putting it up and 10-15 putting it away. Overall good tent!"
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        3) Longeek Camping Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={three} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    320x320x160cm
                                                </li>
                                                <li className='spec-text'>
                                                    2 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    1.5 Kilograms
                                                </li>
                                                <li className='spec-text'>
                                                    Polyethylene base material
                                                </li>

                                                <li className='spec-text'>
                                                    78 Square Feet floor area
                                                </li>
                                                <li className='spec-text' >
                                                    4 Seasons
                                                </li>
                                                <li className='spec-text'>
                                                    1-year limited warranty
                                                </li>
                                                <li className='spec-text'>
                                                    2 doors
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                The tent easily held up in 20-30 mph winds
                                            </li>
                                            <li className='list-padding'>
                                                The Tent material is tough and does not seem like it would tear easily
                                            </li>
                                            <li className='list-padding'>
                                                Easy setup
                                            </li>
                                            <li className='list-padding'>
                                                The tent has two doors with the "weatherproof" style zippers
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                Weak aluminum pole
                                            </li>

                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3y2tXcF'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $118.00 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        Multifunctional dome tent: 1 window 2 mesh air convection holes 2 opposite zipper doors, Each door can be expanded to 2x the size . Window can be used as a fireproof stove jack or lookout hole .The tent has no Carpets, Can promote air circulation to avoid dew. The tent's skirt fixing points can be used in winter to achieve a snow skirt seal and insulation,and it can be turned into a tent with canopy and porch in summer Quick setup instant tent: Can be set up in minutes in any weather. Equipped with high-strength aluminum tent poles, which are stronger than iron rods and do not rust, and high-strength aluminum tent pegs are more stable and easy to use. Reflective windproof rope can prevent you from tripping at night, suitable for hiking, trekking, mountaineering, rock climbing, cycling, motorcycle travel, bird watching, fishing, hunting,family camping.
                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Never owned a hot tent before and was skeptical of the one pole design but after setting it up I was surprised how sturdy it was and there are also rubber covers on each end of the pole. The tent easily held up in 20-30 mph winds but you need to have 6 guy wires to keep it sturdy enough. The Tent material is tough and does not seem like it would tear easily and is made much tougher then most tent rainfly’s. Stayed dry inside testing it in the rain no leaks anywhere. Will be looking forward to testing it with a wood stove in the future. I would definitely recommend this hot tent."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        2) Camppal Backpacking Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={two} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    4.5"L x 4.5"W x 15.5"H
                                                </li>
                                                <li className='spec-text'>
                                                    3 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    3.66 Kilograms
                                                </li>
                                                <li className='spec-text'>
                                                    Easy Setup
                                                </li>

                                                <li className='spec-text'>
                                                    Comfortable Interior: Features double doors, front vestibule, and roomy interior for comfort.
                                                </li>
                                                <li className='spec-text' >
                                                    Durable Oxford Fabric: Made from 3500mm fabric for durability and waterproofing.
                                                </li>
                                                <li className='spec-text'>
                                                    8.5mm Aluminium Pole
                                                </li>

                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Easy to build
                                            </li>
                                            <li className='list-padding'>
                                                Really weatherproof and secure, well made.
                                            </li>
                                            <li className='list-padding'>
                                                The poles are awesome
                                            </li>
                                            <li className='list-padding'>
                                                The bag is nice
                                            </li>

                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            {/* ÜRÜN CONS */}
                                            <li className='list-padding'>
                                                If you need/want space for the family, it's probably not the one for you
                                            </li>


                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/3UG0nlQ '>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $129.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        The coating on the outer fly with the water proofing value of 5000mm which is high and good enough to prevent the water from entering the outer fly of tent even under the big heavy rain. Bathtub Floor Design elevates the bottom of the tent to protect you from wet grounds. This tent comes with a gear loft which enables you to organize your personal items inside your tent. Keep your glasses, flashlight, insect repellant and other lightweight gear off your tent floor but safe and within reach. This gear loft attaches to your tent's clothesline rings or loops to become a storage shelf. The gear Loft is the practical and versatile way to keep your small and most essential gear close at hand. It helps to organize your gear on your next outdoor adventure! The strong aluminium poles construction and the anti tearing rip-stop fabric which greatly help to prevent the hitting and tearing on fabric from the strong wind under the bad weather with big storm which undoubtedly keep you safe and intact inside the tent regardless the change of outdoor weather.
                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "Easy to build. Very spacious for 2 adults, or 1 adult and 1 large breed dog. It would be tight for more. Was deciding between REI, GEERTOP. CAMPPAL is exceptionally well built, it's good choice, during strong winds and rainy nights one feels no worries in this tent. The velcros are well thought adds on. Regarding some other reviewer's concern, the ventilation of 2 windows is sufficient during night. If someone intends to stay inside the tent in 80 F entire afternoon, this tent has 2 exits which allows to open both sides, still have the protection from rain or sunshine, and get cross ventilation and breeze via netted structure, so in my opinion this tent is great for summer as well. Had a safe and worry free camping experience in 40-85 F. Knowledgeable customer service responded quickly to my questions. Matching M066 footprint/tent tarp is useful, sturdy and takes no space."
                                    </p>


                                </div>
                                <div className='blog-column-div'>
                                    {/* ÜRÜNÜN AMAZON ADI */}
                                    <h3 className='product-header'>
                                        1) GoHimal Tent
                                    </h3>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={one} width={'50%'} height={'50%'} ></img>
                                    </div>

                                    <div className='spec-div'>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                                            <b style={{ fontWeight: 'bold', fontSize: '30px', justifyContent: 'center', display: 'flex' }}>
                                                Specs
                                            </b>
                                            <hr className='hr'
                                                style={{
                                                    paddingLeft: '100px',
                                                    paddingRight: '100px',
                                                    background: 'black',
                                                    color: 'black',
                                                    borderColor: 'black',
                                                    height: '2px',
                                                    borderRadius: '15px'
                                                }}
                                            />
                                        </div>
                                        {/* ÜRÜN ÖZELLİKLERİ */}
                                        <div style={{ display: 'flex' }}>
                                            <div className='specs'>
                                                <li className='spec-text'>
                                                    169(L) x 95(W) x 76(H) inches
                                                </li>
                                                <li className='spec-text'>
                                                    8 Occupant Capacity
                                                </li>
                                                <li className='spec-text'>
                                                    It is suitable for 3 queen air mattresses or 8 sleeping bags
                                                </li>
                                                <li className='spec-text'>
                                                    It is an ideal choice for families
                                                </li>

                                                <li className='spec-text'>
                                                    This family tent is easy to set up
                                                </li>
                                                <li className='spec-text' >
                                                    Ideal for outdoor camping, hiking, or car trips in any weather condition.
                                                </li>
                                                <li className='spec-text'>
                                                    The inner layer and outer layer are expertly crafted with 190 T ripstop polyester fabric
                                                </li>
                                                <li className='spec-text'>
                                                    1 large mesh door and 4 mesh windows.
                                                </li>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='pros-cons-div'>
                                        <div style={{ paddingRight: '10vw' }}>
                                            <p className='pros-text'>
                                                Pros
                                            </p>
                                            {/* ÜRÜN PROS */}
                                            <li className='list-padding'>
                                                Roomy for 8 People Family Tent
                                            </li>
                                            <li className='list-padding'>
                                                Easily Assembled and Stored
                                            </li>
                                            <li className='list-padding'>
                                                Breathable and nice Ventilation Design
                                            </li>
                                            <li className='list-padding'>
                                                High-Quality Waterproof Fabric
                                            </li>
                                        </div>
                                        <div >
                                            <p className='cons-text'>
                                                Cons
                                            </p>
                                            <li className='list-padding'>
                                                expensive
                                            </li>
                                            {/* ÜRÜN CONS */}


                                        </div>

                                    </div>
                                    {/* AMAZON LİNKİ ve ÜRÜN FİYATI */}
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href='https://amzn.to/4a36GEr'>
                                            <div className='amazon-button'>
                                                <p style={{ color: 'white', paddingLeft: '15px', paddingRight: '15px' }}>
                                                $139.99 - $169.99 at Amazon
                                                </p>
                                            </div>
                                        </a>

                                    </div>

                                    {/* ÜRÜN AÇIKLAMASI */}
                                    <p className='paragraphs'>
                                        This family tent is easy to set up, with a straightforward design that allows for quick and effortless assembly by two people in 10 minutes and easily disassembled and packed away into its compact carrying bag for convenient storage. The inner layer and outer layer are expertly crafted with 190 T ripstop polyester fabric, offering outstanding waterproofing with its PU2000 mm coating. Unwavering stability is ensured by strong fiberglass poles, promising a long-lasting camping experience.  Ideal for outdoor camping, hiking, or car trips in any weather condition. Whether you're going with friends, or with family, our product is suitable for all occasions. In case you have any concerns or questions, our customer service team is available 24/7 to assist you. Contact us now!
                                    </p>
                                    <h1 className='product-header'>
                                        Customers Review
                                    </h1>
                                    {/* KULLANICI YORUMU */}
                                    <p className='paragraphs' style={{ opacity: '0.8' }}>
                                        "So for starters, I shopped around for a few days before I settled on this product. I chose this product taking a slight risk due to the lack of reviews but I gave it a chance. I really wanted a cheap, sizable tent that was more waterproof than tents I have purchased in the past. I tested the tent during a week long excursion it the woods of maine and found it to be exactly as advertised..... waterproof. It rained for about half the trip, if not more. That being said, I'm very happy with this tent and would recommend it to others without hesitation. Overall a very good tent and it was even super simple to set up!!."
                                    </p>


                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='blog-right-div'>
                        <div className='bottom-padding'>
                            <h2 style={{ display: 'flex', justifyContent: 'center', fontSize: '30px' }}>
                                You Might Also Like
                            </h2>
                            <hr
                                style={{

                                    background: 'black',
                                    color: 'black',
                                    borderColor: 'black',
                                    height: '1px',
                                    borderRadius: '15px',
                                    marginLeft: '10vw',
                                    marginRight: '10vw',
                                    marginBottom: '40px'
                                }}
                            />
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-8-camping-backpacks"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={backpack} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 8 Backpacks For Camping
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 26th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/best-camping-tents-below-100"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={below100} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Best Camping Tents Below $100
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 21st 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                            <div className='might-like-top-div'>
                                <div className='might-like-div'>
                                    <Link to={"/top-5-axes-for-camping"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={axe} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Top 5 Axes for Camping
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            March 16th 2024
                                        </b>
                                    </Link>

                                </div>
                                <div className='might-like-div'>
                                    <Link to={"/best-shoes-for-hikers"}>
                                        <img alt='images' style={{ objectFit: 'contain' }} src={shoes} width={'100%'} height={'50%'} ></img>
                                        <h1 className='paragraphs-bottom'>
                                            Essential Shoe Recommendations for Hikers
                                        </h1>
                                        <b style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', color: 'black', fontWeight: '400' }}>

                                            April 25th 2024
                                        </b>
                                    </Link>

                                </div>

                            </div>
                        </div>




                    </div>

                </div>

            </div>
            <BottomNavBar></BottomNavBar>
        </div>



    )
}

export default bestWinter;