import React from 'react'

import "./App.css"
import "react-router-dom";
import AllBlogs from './pages/AllBlogs';
import Home from './pages/Home';
import { Route, Routes } from "react-router-dom";

import Aboutus from './pages/About-Us';

import PrivacyPolicy from './pages/Privacy-Policy';
import TermsofUse from './pages/Terms-of-Use';
import ScrollToTop from './components/ScrollToTop';
import BestWinter from './blogs/best-tents-for-winter/best-winter-tents.js';
import BestAxes from './blogs/best-axes-for-camping/axes-for-camping.js'
import BestJackets from './blogs/top-5-suggestions-for-winter-jackets/top-5-suggestions-for-winter-jackets.js'
import BestTentsBelow100 from './blogs/best-camping-tents-below-100/best-camping-tents-below-100.js'
import BestBackpacks from './blogs/best-backpacks-for-camping/best-backpacks-for-camping.js'
import BestShoes from './blogs/best-shoes-for-hikers/Step-Into-Adventure-Essential-Shoe-Recommendations-for-Hikers.js'
import BestInsulatedWaterBottles from './blogs/best-insulated-water-bottles-for-camping/best-insulated-water-bottles.js'
import BestKnives from './blogs/best-knives-for-mountain-trips/BestKnivesForMountain.js'
function App() {
  return (
    <>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home title={"Gear Up Frontier"} description={"Explore top blogs, expert guides, and reviews on the best outdoor gear. Gear Up Frontier is your ultimate destination for adventure essentials."} canonicalUrl={"https://gearupfrontier.com"} />} />
        <Route path="/all-blogs" element={<AllBlogs title={"Gear Up Frontier - All Blogs"} description={"Explore top blogs, expert guides, and reviews on the best outdoor gear. Gear Up Frontier is your ultimate destination for adventure essentials."} canonicalUrl={"https://gearupfrontier.com/all-blogs"} />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsofUse />} />
        <Route path='/top-10-tents-for-winter-camping' element={<BestWinter title={"Top 10 Camping Tents for Winter Trips"} description={"One of the most crucial aspects of winter camping is having the right tent. A well-designed winter tent can protect you from chilly temperatures, strong winds, and heavy snowfall."} canonicalUrl={"https://gearupfrontier.com/top-10-tents-for-winter-camping"}/>} />
        <Route path='/top-5-axes-for-camping' element={<BestAxes title={"Top 5 Axes for Camping"} description={"When it comes to camping, having the right tools can make all the difference. One of the most essential tools for any camping trip is a good camping axe. To ensure you're equipped with the best, check out our top 5 axes for camping."} canonicalUrl={"https://gearupfrontier.com/top-5-axes-for-camping"}/>} />
        <Route path='/top-5-suggestions-for-winter-jackets-for-men' element={<BestJackets title={"Top 5 Suggestions For Winter Jackets For Men"} description={"Heading into the great outdoors requires the right gear, and a good jacket is essential. It's your versatile shield against the elements, from chilly mornings to breezy nights. Here are the top 5 camping jackets"} canonicalUrl={"https://gearupfrontier.com/top-5-suggestions-for-winter-jackets-for-men"}/>} />
        <Route path='/best-camping-tents-below-100' element={<BestTentsBelow100 title={"Best Camping Tents Below $100"} description={"Want to experience the natural world without going over budget?  Thankfully, high quality doesn't necessarily have to cost a lot. Look at the top camping tents under $100 in this list."} canonicalUrl={"https://gearupfrontier.com/best-camping-tents-below-100"}/>} />
        <Route path='/top-8-camping-backpacks' element={<BestBackpacks title={"Top 8 Backpacks For Camping"} description={"You'll need a trusty backpack to carry your essentials. having the right backpack can make your outdoor experience more comfortable and organized. In this guide, we'll dive into the best backpacks for camping."} canonicalUrl={"https://gearupfrontier.com/top-8-camping-backpacks"}/>} />
        <Route path='/best-shoes-for-hikers' element={<BestShoes title={"Essential Shoe Recommendations for Hikers"} description={"Let's examine the important variables to take into account while selecting hiking shoes in this guide and offer our top recommendations. Now fasten your boots and let's delve into the realm of hiking apparel."} canonicalUrl={"https://gearupfrontier.com/best-shoes-for-hikers"}/>} />
        <Route path='/best-insulated-water-bottles-for-camping' element={<BestInsulatedWaterBottles title={"Best Insulated Water Bottles for Camping"} description={"Keep your beverages at the perfect temperature on the go with insulated water bottles! Ideal for hiking, the gym, or outdoors, they keep drinks hot or cold and reduce waste."} canonicalUrl={"https://gearupfrontier.com/best-insulated-water-bottles-for-camping"}/>} />      
        <Route path='/best-knives-for-mountain-trips' element={<BestKnives title={"Best Knives for Mountain Trips"} description={"A quality knife is essential for camping. As a knife enthusiast, I'm excited to share this blog with fellow campers. Use knives carefully, only when needed. Happy camping!"} canonicalUrl={"https://gearupfrontier.com/best-knives-for-mountain-trips"}/>} />
      </Routes>
    </>



  );
}

export default App;