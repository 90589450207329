import React from 'react'
import Navbar from '../components/Navbar';

import './Terms-of-Use.css'
import BottomNavBar from '../components/BottomNavBar';

const TermsofUse = () => {
    return (

        <div >
            <div id='pop-bar'>
                <Navbar />
            </div>
            <div style={{ backgroundColor: '#E6EDF2' }}>
                <div className='terms-of-use-main'>
                    <h2 className='terms-of-use-h-font'>
                        Terms of Use
                    </h2>
                    <p className='terms-of-use-p-font'>
                        Gear Up Frontier welcomes you! The guidelines for accessing and utilizing our blog site are described in these Terms of Use. You acknowledge that you have read and agree to the terms by using Gear Up Frontier. You should not use our website if you disagree in any way with these terms.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        1. Getting on the Web page:

                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>1.1. </span> In order to access and use Gear Up Frontier, you must be at least 13 years old. Please do not use our website if you are under 13 years old.
                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>1.2. </span> All activities that take place under your account and the confidentiality of your account credentials are your responsibility.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        2. Copyright:

                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>2.1. </span> Copyright and other intellectual property laws protect the text, graphics, logos, and pictures on Gear Up Frontier.
                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>2.2. </span> Without our prior written consent, you are not permitted to copy, distribute, alter, transmit, or utilize any content from Gear Up Frontier.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        3. Prohibited Behavior:

                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>3.1. </span> You undertake not to take part in any actions that disrupt or interfere with Gear Up Frontier's or its services' ability to operate as intended.
                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>3.2. </span> You are not permitted to try to enter any area of Gear Up Frontier, its servers, or its networks without authorization.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        4. Third-Party Links:

                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>4.1. </span> Gear Up Frontier might have connections to outside websites or services. The practices, privacy policies, or content of these third-party websites are not within our control.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        5. Disclamer of Warranties:

                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>5.1. </span> Gear Up Frontier is made available "as is" and "as available" with no Disclaimer implicit or explicit warranties of any sort.
                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>5.2. </span> Gear Up Frontier may not always function without interruption, without errors, or without viruses or other dangerous elements.
                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        6. Liability Limitation:

                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>6.1. </span> You will never be entitled to compensation for any indirect, incidental, special, consequential, or punitive damages resulting from or related to your use of the website from Gear Up Frontier or its owners, partners, employees, or affiliates.

                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        7. Liability Limitation:

                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>7.1. </span> Terms of Use of the Gear Up Frontier may be changed or updated at any time. It is your obligation to check these terms for updates on a regular basis.

                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        8. Governing Law:

                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>8.1. </span> Without regard to its conflict of law provisions, the laws of the United States of America shall govern and be construed in accordance with these Terms of Use.

                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        9. Get in Touch:

                    </p>
                    <p className='terms-of-use-p-font'>
                        <span style={{ fontWeight: '700' }}>9.1. </span> Please email us at contact@gearupfrontier.com with any queries or worries you may have regarding our Terms of Use.

                    </p>
                    <p style={{ fontSize: '25px', paddingTop: '30px', fontWeight: '700' }}>
                        The last modification to these terms of use was made on March 14 2024.

                    </p>

                </div>
            </div>

            <BottomNavBar></BottomNavBar>
        </div>

    )
}

export default TermsofUse;